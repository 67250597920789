import { compose, withHooks } from "enhancers";
import {
  Box,
  Typography,
  Field,
  Button,
  FieldArray,
  TextField,
} from "components";
import { get, range } from "lodash";
import { ImageUploadList } from ".";
import styled from "styled-components/macro";
import { ReactComponent as AddImageIcon } from "assets/icon/add_image.svg";
import { Modal, Notification } from "components/common";

const DeleteButton = styled(Button)({
  backgroundColor: "#fff",
  borderColor: "#F34336",
  color: "#F34336",
  "&.MuiButton-contained": {
    "& path": {
      fill: "#F34336",
    },
  },
});

const ExampleProductList = (props) => (
  <Box {...props}>
    <Box display="flex" alignItems="center" mb={6} mt={6}>
      <Typography variant="h4" flex={1}>
        รายการตัวอย่างงาน
      </Typography>
      <Typography variant="body1" color="secondary">
        ทั้งหมด {props.amount}
      </Typography>
    </Box>
    {range(props.amount).map((index) => {
      return (
        <Box key={index}>
          <Box display="flex" flexDirection="column">
            <FieldArray
              component={ImageUploadList}
              name={`${props.name}[${index}].productImages`}
              addButtonLabel="เพิ่มรูปตัวอย่างงาน"
              caption={`ตัวอย่าง ${index + 1}`}
              max={7}
              onRemoveImage={(imageIndex, innerProps) =>
                props.deleteImage(index, imageIndex, innerProps)
              }
            />
          </Box>
          <Box display="flex" flexDirection="column" flex={1} mt={6}>
            <Field
              component={TextField}
              name={`${props.name}[${index}].productTitle`}
              type=""
              label="หัวข้อ"
            />
          </Box>
          <Box display="flex" flexDirection="column" flex={1} mt={6}>
            <Field
              component={TextField}
              name={`${props.name}[${index}].productDescription`}
              type=""
              fullWidth
              multiline
              className="height-136"
              label="รายละเอียด"
              mb={6}
            />
          </Box>
          <Box mb={6}>
            <DeleteButton
              startIcon="bigTrash"
              onClick={() => {
                props.remove(index);
              }}
            >
              ลบรายการตัวอย่างงาน
            </DeleteButton>
          </Box>
        </Box>
      );
    })}
    <Button
      startIcon={<AddImageIcon />}
      style={{
        borderColor: "#233044",
      }}
      onClick={props.push}
    >
      {props.addButtonLabel}
    </Button>
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      form,
      name,
      push,
      remove,
      title,
      addButtonLabel,
      mt,
      type,
      hasGmap,
      onRemoveImage,
    } = props;
    const { useCallback } = hooks;
    const amount = get(form.values, name)?.length ?? 0;

    const customPush = useCallback(() => {
      push({});
    }, [push]);

    const customRemove = useCallback(
      (index) => {
        Modal.open({
          title: "ลบข้อมูลตัวอย่างงาน",
          children: (
            <Box flex={1} mb={5} display="flex" flexDirection="row">
              <Typography variant="body1" color="#767676">
                {`ตัวอย่างงาน ${index + 1} จะถูกลบและ`}
              </Typography>
              <Typography variant="body1" color="#F34336">
                ไม่สามารถกู้คืนได้
              </Typography>
            </Box>
          ),
          cancelButtonLabel: "ยกเลิก",
          okButtonLabel: "ลบข้อมูล",
          onOk: async ({ close }) => {
            remove(index);
            close();
          },
        });
      },
      [remove]
    );

    const deleteImage = useCallback(
      (index, imageIndex, props) => {
        const { form } = props;
        onRemoveImage(index, imageIndex, form.values);
      },
      [onRemoveImage]
    );

    return {
      name,
      amount,
      push: customPush,
      remove: customRemove,
      title,
      addButtonLabel,
      mt,
      type,
      hasGmap,
      deleteImage,
    };
  })
);

export default enhancer(ExampleProductList);
