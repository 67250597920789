import React from "react";
import { compose, withStores, withHooks } from "enhancers";
import { Box, Typography, Avatar, ClickAwayListener, Modal } from "components";
import { paths, gql } from "utils/helper";
import { removeToken } from "api";

import { ReactComponent as ArrowUpIcon } from "assets/icon/keyboard_arrow_up.svg";
import { ReactComponent as ArrowDownIcon } from "assets/icon/keyboard_arrow_down.svg";
import { ReactComponent as ProfileIcon } from "assets/icon/profile_icon.svg";
import { ReactComponent as LogoutIcon } from "assets/icon/logout_icon.svg";

import styled from "styled-components/macro";

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 12px 16px;
  transition: all 0.2s;

  :hover {
    background-color: #e0e0e0;
    cursor: pointer;
  }
`;

const PopMenu = ({
  Icon,
  SidebarFooterText,
  SidebarFooterSubText,
  ...props
}) => (
  <Box
    display="flex"
    width="100%"
    alignItems="center"
    // onClick={props.toggle}
    style={{ cursor: "pointer" }}
    position="relative"
    onClick={props.gotoProfilePage}
  >
    {/*props.expanded && (
      <ClickAwayListener onClickAway={props.close}>
        <Box
          style={{ backgroundColor: "white" }}
          position="absolute"
          width={226}
          borderRadius={8}
          bottom={68}
          right={0}
          py={4}
        >
          <MenuItem onClick={props.gotoProfilePage}>
            <ProfileIcon />
            <Typography ml={4}>โปรไฟล์</Typography>
          </MenuItem>
        </Box>
      </ClickAwayListener>
    )*/}
    <Box>
      <Avatar
        style={{
          backgroundColor: "#376FD0",
          fontSize: 16,
          fontWeight: "bold",
        }}
        src={props.avatarUrl}
      >
        ML
      </Avatar>
    </Box>
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      ml={4}
      overflow="hidden"
    >
      <SidebarFooterText variant="body2" noWrap>
        {props.firstName} {props.lastName}
      </SidebarFooterText>
      <SidebarFooterSubText variant="caption" noWrap>
        {props.code}
      </SidebarFooterSubText>
    </Box>
    <Box ml={2}>
      {/*<Box
        width={24}
        height={24}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon />
      </Box>*/}
    </Box>
  </Box>
);

export const API = {
  FETCH_CURRENT_USER: gql`
    query FETCH_CURRENT_USER {
      currentUser {
        ownerType
        admin {
          isSuperAdmin
        }
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withHooks((props, hooks) => {
    const { SidebarFooterText, SidebarFooterSubText, currentUser } = props;
    const { firstName, lastName, code, avatarUrl } = currentUser ?? {};

    const { useState, useCallback, useQuery } = hooks;
    const [expanded, setExpanded] = useState(false);

    const { data } = useQuery(API.FETCH_CURRENT_USER);

    const toggle = useCallback(() => {
      setExpanded(!expanded);
    }, [expanded, setExpanded]);

    const close = useCallback(() => {
      setExpanded(false);
    }, [setExpanded]);

    const Icon = expanded ? ArrowUpIcon : ArrowDownIcon;

    const gotoProfilePage = useCallback(() => {
      paths.profilePath().push();
    }, [data]);

    const gotoSettingPage = useCallback(() => {
      paths.settingPath().push();
    }, []);

    const logout = useCallback(() => {
      Modal.open({
        title: "ออกจากระบบ",
        children: `ยืนยันการออกจากระบบ`,
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ออกจากระบบ",
        onOk: ({ close }) => {
          removeToken();
          close();
          window.location.reload();
        },
      });
    }, []);

    const currentUserIsSuperAdmin = data?.currentUser?.admin?.isSuperAdmin;

    return {
      expanded,
      Icon,
      toggle,
      close,
      gotoProfilePage,
      gotoSettingPage,
      logout,
      firstName,
      lastName,
      code,
      avatarUrl,
      SidebarFooterText,
      SidebarFooterSubText,
      currentUserIsSuperAdmin,
    };
  })
);

export default enhancer(PopMenu);
