import { compose, withHooks, withFormik } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Typography,
  Form,
  Field,
  TextField,
  Button,
  ErrorMessage,
  Notification,
  UploadAvatar,
  Link,
  Modal,
} from "components";
import { gql, paths, Yup, notifyError, getErrorMessage } from "utils/helper";
import { removeToken } from "api";
import appStore from "stores/appStore";
import { isEqual } from "lodash";
import AdminProfilePage from "./AdminProfile";
import SupplierProfilePage from "./SupplierProfile";

const ProfilePage = (props) =>
  props.loading ? null : props.isAdmin ? (
    <AdminProfilePage />
  ) : (
    <SupplierProfilePage id={props.id} />
  );

export const API = {
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      currentUser {
        ownerType
        owner {
          id
          code
          firstName
          lastName
          phoneNumber
          email
          line
          avatarUrl
        }
      }
    }
  `,
  UPDATE_ADMIN: gql`
    mutation UPDATE_ADMIN(
      $id: String!
      $firstName: String
      $lastName: String
      $phoneNumber: String
      $email: String
      $line: String
      $avatarFile: Upload
    ) {
      editAdminInfo(
        input: {
          id: $id
          firstName: $firstName
          lastName: $lastName
          phoneNumber: $phoneNumber
          email: $email
          line: $line
          avatarFile: $avatarFile
        }
      ) {
        admin {
          id
          code
          firstName
          lastName
          phoneNumber
          email
          line
          avatarUrl
        }
      }
    }
  `,
  UPDATE_SUPPLIER: gql`
    mutation UPDATE_SUPPLIER(
      $id: String!
      $firstName: String
      $lastName: String
      $phoneNumber: String
      $email: String
      $line: String
      $nickName: String
      $avatarFile: Upload
    ) {
      editSupplierInfo(
        input: {
          id: $id
          firstName: $firstName
          lastName: $lastName
          nickName: $nickName
          phoneNumber: $phoneNumber
          email: $email
          line: $line
          avatarFile: $avatarFile
        }
      ) {
        supplier {
          id
          code
          firstName
          lastName
          phoneNumber
          email
          line
          avatarUrl
        }
      }
    }
  `,
  RESET_PASSWORD: gql`
    mutation RESET_PASSWORD($email: String!) {
      resetPassword(input: { email: $email }) {
        currentUser {
          email
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      firstName: "",
      lastName: "",
      nickName: "",
      phoneNumber: "",
      email: "",
      line: "",
      avatarFile: null,
    }),
    validationSchema: Yup.object().shape({
      email: Yup.string().nullable().email("รูปแบบอีเมลไม่ถูกต้อง"),
      firstName: Yup.string().nullable().required("ชื่อต้องไม่เว้นว่างเอาไว้"),
      lastName: Yup.string()
        .nullable()
        .required("นามสกุลต้องไม่เว้นว่างเอาไว้"),
    }),
  }),
  withHooks((props, hooks) => {
    const { setErrors, setValues, values } = props;
    const {
      useQuery,
      useMutation,
      useMemo,
      useHandleSubmit,
      useEffect,
      useCallback,
    } = hooks;
    const { loading, data, error } = useQuery(API.GET_CURRENT_USER);

    const [updateAdmin] = useMutation(API.UPDATE_ADMIN, {
      onCompleted: (data) => {
        appStore.setCurrentUser(data.editAdminInfo.admin);
      },
    });

    const [updateSupplier] = useMutation(API.UPDATE_SUPPLIER, {
      onCompleted: (data) => {
        appStore.setCurrentUser(data.editSupplierInfo.supplier);
      },
    });

    const [resetPasswordApi] = useMutation(API.RESET_PASSWORD);

    // previous code
    // const admin = useMemo(() => {
    //   if (loading || error) {
    //     return null;
    //   }
    //   return data.currentUser.admin;
    // }, [loading, data, error]);

    const owner = useMemo(() => {
      if (loading || error) {
        return null;
      }
      return data.currentUser.owner;
    }, [loading, data, error]);

    const ownerType = useMemo(() => {
      if (loading || error) {
        return null;
      }
      return data.currentUser.ownerType;
    }, [loading, data, error]);

    // previous code
    // useEffect(() => {
    //   setValues(admin);
    // }, [setValues, admin]);

    useEffect(() => {
      setValues(owner);
    }, [setValues, owner]);

    useHandleSubmit(
      async (values) => {
        try {
          switch (ownerType) {
            case "Admin":
              await updateAdmin({
                variables: values,
              });
              break;
            case "Supplier":
              await updateSupplier({
                variables: values,
              });
              break;
            default:
              break;
          }
          Notification.success("แก้ไขข้อมูลสำเร็จ");
        } catch (e) {
          const errorMessage = getErrorMessage(e);
          let errorMessageData = { __error__: errorMessage };

          if (errorMessage === "มีเบอร์โทรนี้ในระบบแล้ว") {
            errorMessageData = {
              ...errorMessageData,
              phoneNumber: errorMessage,
            };
          }

          if (errorMessage === "มีไลน์ไอดีในระบบแล้ว") {
            errorMessageData = {
              ...errorMessageData,
              line: errorMessage,
            };
          }
          setErrors(errorMessageData);
        }
      },
      [updateAdmin, updateSupplier, ownerType]
    );

    // previous code
    // const email = admin?.email;
    const email = owner?.email;

    const resetPassword = useCallback(async () => {
      if (!email) {
        return;
      }
      Modal.open({
        title: "เปลี่ยนรหัสผ่าน",
        children: `ลิงก์สำหรับสร้างรหัสผ่านใหม่จะถูกส่งไปที่อีเมล ${email} กรุณาคลิกลิงก์จากอีเมลเพื่อสร้างรหัสผ่านในการเข้าสู่ระบบ`,
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ส่งเมล",
        onOk: async () => {
          try {
            await resetPasswordApi({ variables: { email } });
            removeToken();
            window.location.reload();
          } catch (error) {
            notifyError(error);
          }
        },
      });
    }, [email, resetPasswordApi]);

    // previous code
    // const disabledSubmitButton = useMemo(
    //   () => isEqual(values, data?.currentUser.admin),
    //   [values, data]
    // );

    const disabledSubmitButton = useMemo(
      () => isEqual(values, data?.currentUser.owner),
      [values, data]
    );

    const isAdmin = useMemo(() => {
      return ownerType == "Admin";
    }, [ownerType]);

    // previous code
    // return { code: admin?.code, resetPassword, disabledSubmitButton };
    return { isAdmin, loading, id: owner?.id };
  })
);

export default enhancer(ProfilePage);
