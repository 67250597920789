import { compose, withHooks } from "enhancers";
import { Typography, Box, Field, TextField } from "components";
import { isEmpty } from "lodash";

const CancelModalDetail = (props) => (
  <Box flex={1} mb={5} display="flex" flexDirection="column">
    <Typography variant="body1" color="black" fontWeight={600}>
      ปฏิเสธการจองคิว
    </Typography>
    <Box flex={1} display="flex" flexDirection="column" mt={2}>
      <Typography variant="body1" color="#767676">
        ระบบจะแจ้งเหตุผลการปฏิเสธกลับไปยังลูกค้า
      </Typography>
    </Box>
    <Box flex={1} mt={3} display="flex" flexDirection="column">
      <Field
        component={TextField}
        name="description"
        type="text"
        label="เหตุผล"
        required
        fullWidth
        multiline
        className="height-136"
        {...props}
      />
    </Box>
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useHandleSubmit } = hooks;
    const { errors, submitCount, onSubmit } = props;

    const error = useMemo(() => {
      if (!isEmpty(errors) && submitCount > 0) {
        return errors;
      } else {
        return null;
      }
    }, [errors, submitCount]);

    useHandleSubmit(
      async (values) => {
        onSubmit && onSubmit(values);
      },
      [onSubmit]
    );

    return { error };
  })
);

export default enhancer(CancelModalDetail);
