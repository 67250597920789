import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography, ExcelGenerator } from "components";
import { gql, paths, formatDate } from "utils/helper";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { BOOKING_STATUS_TEXT } from "../../../../constants/index";

import { ReactComponent as DownloadIcon } from "assets/icon/download-icon-white.svg";

const BookingIndexPage = (props) => (
  <PageContent
    title="การจองคิว"
    pageActions={[
      {
        children: "Export",
        startIcon: <DownloadIcon />,
        onClick: props.downloadProducts,
        color: "primary",
      },
    ]}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายการจองคิว
      </Typography>
      <Table
        columns={[
          { width: 123, field: "code", headerName: "รหัส" },
          {
            width: 200,
            field: "supplierName",
            headerName: "ชื่อช่าง",
          },
          {
            width: 93,
            field: "productType",
            headerName: "ประเภท",
          },
          {
            width: 77,
            field: "productGrade",
            headerName: "เกรด",
          },
          {
            width: 70,
            field: "productAmout",
            headerName: "จำนวน",

            align: "right",
          },
          {
            width: 175,
            field: "productReceivedDueDate",
            headerName: "วันที่ต้องการรับของ",
            type: "dateOnly",
          },
          {
            width: 200,
            field: "customerName",
            headerName: "ชื่อลูกค้า",
          },
          {
            width: 150,
            field: "bookingStatus",
            headerName: "สถานะ",
            type: "status",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.bookingDetailPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_BOOKINGS: gql`
    query FETCH_BOOKINGS {
      bookings {
        id
        code
        supplierName
        customerName
        productType
        productGrade
        productAmout
        productReceivedDueDate
        bookingStatus
      }
    }
  `,
  CREATE_BOOKING: gql`
    mutation CREATE_BOOKING($id: String) {
      createBooking(input: { id: $id }) {
        booking {
          productType
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useCallback, useQuery, useMutation, useEffect } = hooks;

    const { loading, data, error, refetch } = useQuery(API.FETCH_BOOKINGS);
    const [createBooking] = useMutation(API.CREATE_BOOKING);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.bookings.map((booking, index) => {
        return {
          ...booking,
          actions: [],
        };
      });
    }, [loading, data, error]);

    const addMockupBooking = useCallback(async () => {
      await createBooking();
    }, [createBooking]);

    const excelData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      const bookings = data.bookings.map((booking) => {
        const status = BOOKING_STATUS_TEXT[booking.bookingStatus];

        return {
          ...booking,
          bookingStatus: status,
        };
      });

      return bookings;
    }, [loading, data, error]);

    const downloadProducts = useCallback(async () => {
      ExcelGenerator.generate({
        fileName: `bookings-${formatDate(new Date(), "yyyyMMddhhmmss")}`,
        columns: [
          {
            title: "รหัส",
            field: "code",
          },
          {
            title: "ชื่อช่าง",
            field: "supplierName",
          },
          {
            title: "ประเภท",
            field: "productGrade",
          },
          {
            title: "จำนวน",
            field: "productAmout",
          },
          {
            title: "วันที่ต้องการรับของ",
            field: "productReceivedDueDate",
          },
          {
            title: "ชื่อลูกค้า",
            field: "customerName",
          },
          {
            title: "สถานะ",
            field: "bookingStatus",
          },
        ],
        data: excelData,
      });
    }, [excelData]);

    return {
      downloadProducts,
      addMockupBooking,
      tableData,
      loading,
    };
  })
);

export default enhancer(BookingIndexPage);
