import { compose, withHooks, withFormik } from "enhancers";
import { PageContent } from "layouts";
import styled from "styled-components/macro";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Field,
  TextField,
  JsonEditor,
  Button,
  Divider,
  Form,
  Notification,
  ErrorMessage,
} from "components";
import { gql, Yup } from "utils/helper";
import * as constants from "../../../constants";
import { isEmpty, find } from "lodash";

import PDF from "../../../assets/SystemConfig.pdf";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#2296F3",
    borderColor: "#2296F3",
    color: "white",
    "&:hover": {
      backgroundColor: "#2296F3",
      borderColor: "#2296F3",
      color: "white",
    },
  },
}));

const MenuItem = styled.div`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const ConfigPage = (props) => (
  <PageContent title="ตั้งค่า">
    <Form>
      <Box width="100%" mb={-6}>
        <Typography variant="h4" mb={4}>
          การคำนวณราคา
        </Typography>
        <Box display="flex" flex={1}>
          <Box display="flex" flexDirection="column" flex={1} mr={6}>
            <Field
              component={TextField}
              name="patternPrice"
              type="number"
              label="ค่าแพทเทิร์น"
              required
            />
          </Box>
          <Box display="flex" flexDirection="column" flex={1} ml={6}>
            <Field
              component={TextField}
              name="customSizeCost"
              type="number"
              label="ค่าเกลี่ยไซส์"
              required
            />
          </Box>
        </Box>
        <Box display="flex" flex={1} justifyContent="space-between">
          <Typography variant="h4" mt={12} mb={6}>
            JSON Config
          </Typography>
          <MenuItem onClick={props.gotoConfigTutorial}>
            <Typography variant="body" mt={12} mb={6} color="#2296F3">
              วิธีการใส่ค่า Config ของระบบ
            </Typography>
          </MenuItem>
        </Box>
        <ErrorMessage with="100%" name="__error__" mt={6} />
        <Field component={JsonEditor} name="detail" />
        <Divider mt={10} />
        <Button
          type="submit"
          // color="primary"
          mt={6}
          mb={6}
          width={74}
          className={props.classes.button}
        >
          บันทึก
        </Button>
      </Box>
    </Form>
  </PageContent>
);

export const API = {
  FETCH_SUPPLIERS: gql`
    query FETCH_SUPPLIERS {
      config {
        id
        patternPrice
        customSizeCost
        detail
      }
    }
  `,
  UPDATE_CONFIGS: gql`
    mutation UPDATE_CONFIGS(
      $customSizeCost: Float
      $patternPrice: Float
      $detail: String
    ) {
      updateConfigInfo(
        input: {
          patternPrice: $patternPrice
          customSizeCost: $customSizeCost
          detail: $detail
        }
      ) {
        config {
          patternPrice
          customSizeCost
          detail
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: (props) => ({
      detail:
        props.configsValue?.detail || JSON.parse(constants.INITIAL_CONFIGS),
    }),
    validationSchema: Yup.object().shape({
      detail: Yup.object()
        .shape({
          productTypes: Yup.array()
            .of(
              Yup.object()
                .shape({
                  label: Yup.string()
                    .required("label ของ productTypes ต้องไม่เว้นว่างไว้")
                    .typeError(
                      "label ของ productTypes ต้องเป็นตัวอักษรเท่านั้น"
                    ),
                  grades: Yup.array()
                    .of(
                      Yup.object()
                        .shape({
                          label: Yup.string()
                            .required(
                              "grade label ใน productTypes ต้องไม่เว้นว่างไว้"
                            )
                            .typeError(
                              "label ของ grades ใน productTypes ต้องเป็นตัวอักษรเท่านั้น"
                            )
                            .test({
                              name: "equal",
                              exclusive: true,
                              message:
                                "label ใน productTypes ต้องเป็นค่าใน grades เท่านั้น",
                              test: function (value) {
                                const valid = find(
                                  this.options.from[2].value.grades,
                                  (grade) => grade.label === value
                                );
                                return valid;
                              },
                            }),
                          min_price: Yup.number()
                            .integer(
                              "min_price ใน productTypes ต้องเป็นตัวเลขเท่านั้น"
                            )
                            .min(
                              0,
                              "min_price ใน productTypes ต้องมากกว่าหรือเท่ากับ 0"
                            )
                            .required(
                              "min_price ใน productTypes ต้องไม่เว้นว่างไว้"
                            )
                            .typeError(
                              "min_price ใน productTypes ต้องเป็นตัวเลขเท่านั้น"
                            ),
                          max_price: Yup.number()
                            .integer(
                              "max_price ใน productTypes ต้องเป็นตัวเลขเท่านั้น"
                            )
                            // .moreThan(
                            //   Yup.ref("min_price"),
                            //   "max_price ใน productTypes ต้องมากกว่า min_price"
                            // )
                            .required("max_price ต้องไม่เว้นว่างไว้")
                            .typeError(
                              "max_price ใน productTypes ต้องเป็นตัวเลขเท่านั้น"
                            )
                            .test({
                              name: "equalOrGreaterThan",
                              exclusive: true,
                              message:
                                "max_price ใน productTypes ต้องมากกว่าหรือเท่ากับ min_price",
                              test: function (value) {
                                return (
                                  value >= parseFloat(this.parent.min_price)
                                );
                              },
                            }),
                        })
                        .typeError(
                          "ค่าของ grades ใน productTypes ต้องเป็น object เท่านั้น"
                        )
                    )
                    .typeError(
                      "grades ของ productTypes ต้องเป็น array เท่านั้น"
                    ),
                })
                .typeError("ค่าใน productTypes ต้องเป็น object เท่านั้น")
            )
            .typeError("productTypes ต้องเป็น array เท่านั้น"),
          productDetails: Yup.array()
            .of(
              Yup.object()
                .shape({
                  label: Yup.string()
                    .required("label ใน productDetails ต้องไม่เว้นว่างไว้")
                    .typeError(
                      "label ของ productDetails ต้องเป็นตัวอักษรเท่านั้น"
                    ),
                  grades: Yup.array()
                    .of(
                      Yup.object()
                        .shape({
                          label: Yup.string()
                            .required(
                              "grade label ใน productDetails ต้องไม่เว้นว่างไว้"
                            )
                            .typeError(
                              "label ของ grades ใน productDetails ต้องเป็นตัวอักษรเท่านั้น"
                            )
                            .test({
                              name: "equal",
                              exclusive: true,
                              message:
                                "label ใน productDetails ต้องเป็นค่าใน grades เท่านั้น",
                              test: function (value) {
                                const valid = find(
                                  this.options.from[2].value.grades,
                                  (grade) => grade.label === value
                                );
                                return valid;
                              },
                            }),
                          min_price: Yup.number()
                            .integer(
                              "min_price ใน productDetails ต้องเป็นตัวเลขเท่านั้น"
                            )
                            .min(0)
                            .required(
                              "min_price ใน productDetails ต้องไม่เว้นว่างไว้"
                            )
                            .typeError(
                              "min_price ใน productDetails ต้องเป็นตัวเลขเท่านั้น"
                            ),
                          max_price: Yup.number()
                            .integer(
                              "max_price ใน productDetails ต้องเป็นตัวเลขเท่านั้น"
                            )
                            // .moreThan(
                            //   Yup.ref("min_price"),
                            //   "max_price ใน productDetails ต้องมากกว่า min_price"
                            // )
                            .required(
                              "max_price ใน productDetails ต้องไม่เว้นว่างไว้"
                            )
                            .typeError(
                              "max_price ใน productDetails ต้องเป็นตัวเลขเท่านั้น"
                            )
                            .test({
                              name: "equalOrGreaterThan",
                              exclusive: true,
                              message:
                                "max_price ใน productDetails ต้องมากกว่าหรือเท่ากับ min_price",
                              test: function (value) {
                                return (
                                  value >= parseFloat(this.parent.min_price)
                                );
                              },
                            }),
                        })
                        .typeError(
                          "ค่าของ grades ใน productDetails ต้องเป็น object เท่านั้น"
                        )
                    )
                    .typeError(
                      "grades ของ productDetails ต้องเป็น array เท่านั้น"
                    ),
                })
                .typeError("ค่าใน productDetails ต้องเป็น object เท่านั้น")
            )
            .typeError("productDetails ต้องเป็น array เท่านั้น"),
          grades: Yup.array()
            .of(
              Yup.object().shape({
                label: Yup.string()
                  .required("grade label ต้องไม่เว้นว่างไว้")
                  .typeError("label ของ grades ต้องเป็นตัวอักษรเท่านั้น"),
                description: Yup.string()
                  .required("description ต้องไม่เว้นว่างไว้")
                  .typeError("description ของ grades ต้องเป็นตัวอักษรเท่านั้น"),
              })
            )
            .typeError("grades ต้องเป็น array เท่านั้น"),
          productSizes: Yup.array()
            .of(
              Yup.object().shape({
                label: Yup.string()
                  .required("productSizes label ต้องไม่เว้นว่างไว้")
                  .typeError("label ของ productSizes ต้องเป็นตัวอักษรเท่านั้น"),
              })
            )
            .typeError("productSizes ต้องเป็น array เท่านั้น"),
          productColors: Yup.array()
            .of(
              Yup.object().shape({
                label: Yup.string()
                  .required("productColors label ต้องไม่เว้นว่างไว้")
                  .typeError(
                    "label ของ productColors ต้องเป็นตัวอักษรเท่านั้น"
                  ),
              })
            )
            .typeError("productColors ต้องเป็น array เท่านั้น"),
          // deliveredFrom: Yup.array()
          //   .of(
          //     Yup.object().shape({
          //       label: Yup.string()
          //         .required("deliveredFrom label ต้องไม่เว้นว่างไว้")
          //         .typeError(
          //           "label ของ deliveredFrom ต้องเป็นตัวอักษรเท่านั้น"
          //         ),
          //     })
          //   )
          //   .typeError("deliveredFrom ต้องเป็น array เท่านั้น"),
          supplierTypes: Yup.array()
            .of(
              Yup.object().shape({
                label: Yup.string()
                  .required("supplierTypes label ต้องไม่เว้นว่างไว้")
                  .typeError(
                    "label ของ supplierTypes ต้องเป็นตัวอักษรเท่านั้น"
                  ),
              })
            )
            .typeError("supplierTypes ต้องเป็น array เท่านั้น"),
          materialTypes: Yup.array()
            .of(
              Yup.object().shape({
                label: Yup.string()
                  .required("materialTypes label ต้องไม่เว้นว่างไว้")
                  .typeError(
                    "label ของ materialTypes ต้องเป็นตัวอักษรเท่านั้น"
                  ),
              })
            )
            .typeError("materialTypes ต้องเป็น array เท่านั้น"),
          materialUnits: Yup.array()
            .of(
              Yup.object().shape({
                label: Yup.string()
                  .required("materialUnits label ต้องไม่เว้นว่างไว้")
                  .typeError(
                    "label ของ materialUnits ต้องเป็นตัวอักษรเท่านั้น"
                  ),
              })
            )
            .typeError("materialUnits ต้องเป็น array เท่านั้น"),
          frontOfficeTermAndConditionUrl: Yup.string()
            .required("frontOfficeTermAndConditionUrl ต้องไม่เว้นว่างไว้")
            .typeError(
              "frontOfficeTermAndConditionUrl ต้องเป็นตัวอักษรเท่านั้น"
            ),
          frontOfficeConsentUrl: Yup.string()
            .required("frontOfficeConsentUrl ต้องไม่เว้นว่างไว้")
            .typeError("frontOfficeConsentUrl ต้องเป็นตัวอักษรเท่านั้น"),
        })
        .typeError("detail ของ configs ต้องเป็น object เท่านั้น"),
    }),
  }),
  withHooks((props, hooks) => {
    const {
      useMemo,
      useQuery,
      useMutation,
      useEffect,
      useHandleSubmit,
      useCallback,
    } = hooks;
    const {
      setErrors,
      setValues,
      errors,
      touched,
      setPropsToFormikBag,
    } = props;
    const { loading, data, error, refetch } = useQuery(API.FETCH_SUPPLIERS);
    const [updateConfigs] = useMutation(API.UPDATE_CONFIGS);
    const classes = useStyles();

    const configs = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data?.config;
    }, [loading, data]);

    useEffect(() => {
      refetch();
    }, [refetch]);

    useEffect(() => {
      const detail = configs.detail
        ? JSON.parse(configs.detail)
        : JSON.parse(constants.INITIAL_CONFIGS);
      const configsValue = { ...configs, detail: detail };
      setValues(configsValue);
      setPropsToFormikBag({ configsValue: configsValue });
    }, [JSON.stringify(configs), setPropsToFormikBag]);

    const getErrorMessage = useCallback(
      (currentObj, key) => {
        if (typeof currentObj[key] === "object" && !isEmpty(currentObj[key])) {
          const errorKeys = Object.keys(currentObj[key]);
          getErrorMessage(currentObj[key], errorKeys[0]);
        } else {
          const errorMessage = `${currentObj[key]}`;
          setErrors({
            __error__: errorMessage,
          });
          return;
        }
      },
      [errors]
    );

    useEffect(() => {
      if (!isEmpty(errors) && !isEmpty(touched)) {
        const errorKeys = Object.keys(errors);

        if (errorKeys[0] === "__error__") {
          return;
        }

        getErrorMessage(errors, errorKeys[0]);
      }
    }, [errors, JSON.stringify(touched), getErrorMessage]);

    useHandleSubmit(
      async (values) => {
        try {
          const { detail } = values || {};
          const detailStringify = JSON.stringify(detail);
          const formValues = { ...values, detail: detailStringify };
          await updateConfigs({ variables: formValues });
          Notification.success("แก้ไขข้อมูลสำเร็จ");
        } catch (e) {
          setErrors({
            __error__: e.toString(),
          });
        }
      },
      [updateConfigs, setValues, setPropsToFormikBag]
    );

    const gotoConfigTutorial = useCallback(() => {
      // window.open(
      //   "https://docs.google.com/spreadsheets/d/13_qBbEjYnQMeF_VEFSTomMkMf33MWO5t6CwkupWh1P4/edit#gid=0",
      //   "_blank"
      // );
      window.open(PDF, "_blank");
    }, []);

    return {
      classes,
      gotoConfigTutorial,
    };
  })
);

export default enhancer(ConfigPage);
