import React from "react";
import { compose, defaultProps, withHooks } from "enhancers";
import { makeStyles } from "@material-ui/core/styles";
import {
  Helmet,
  Box,
  Typography,
  Breadcrumbs,
  Link,
  Divider,
  Paper,
  Button,
  BrowseButton,
} from "components";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#2296F3",
    borderColor: "#2296F3",
    color: "white",
    "&:hover": {
      backgroundColor: "#2296F3",
      borderColor: "#2296F3",
      color: "white",
    },
  },
}));

const PageContent = (props: any) => (
  <Box
    minWidth={props.isMobileSize ? 0 : 786}
    maxWidth={props.isMobileSize ? 786 : 1080}
    mx="auto"
  >
    <Helmet title={props.title} />
    <Box display="flex">
      <Typography flex={1} variant="h2">
        {props.title}
      </Typography>
      {props.pageActions.map((actionProps: any, index: any) => (
        <>
          {actionProps.type !== "file" && (
            <Button
              key={index}
              ml={4}
              {...actionProps}
              className={props.classes.button}
            />
          )}
          {actionProps.type === "file" && (
            <BrowseButton key={index} ml={4} {...actionProps} />
          )}
        </>
      ))}
    </Box>
    {props.breadcrumbs ? (
      <>
        <Breadcrumbs aria-label="breadcrumb" mt={2}>
          {props.breadcrumbs.map(({ label, path }: any, index: any) => (
            <Link key={index} color={path ? undefined : "inherit"} to={path}>
              {label}
            </Link>
          ))}
        </Breadcrumbs>
        <Divider mt={6} />
        {props.banner}
      </>
    ) : (
      <>
        <Divider mt={13} />
        {props.banner}
      </>
    )}
    {props.paper ? (
      props.noPadding ? (
        <Paper mt={6}>{props.children}</Paper>
      ) : (
        <Paper mt={6} px={4} py={6}>
          {props.children}
        </Paper>
      )
    ) : (
      props.children
    )}
  </Box>
);

const enhancer = compose(
  defaultProps({
    paper: true,
  }),
  withHooks((props: any, hooks: any) => {
    const {
      title,
      breadcrumbs,
      pageActions = [],
      children,
      paper,
      banner,
      noPadding,
    } = props;
    const isMobileSize = useMediaQuery("(max-width: 786px)");
    const classes = useStyles();
    return {
      title,
      breadcrumbs,
      pageActions,
      children,
      paper,
      isMobileSize,
      banner,
      classes,
      noPadding,
    };
  })
);

export default enhancer(PageContent);
