import qs from "qs";
import { last } from "lodash";
import { history } from "components/common/BrowserRouter";

// prettier-ignore
export class Path extends String {
  newTab = () => {
    window.open(this.toString(), '__blank')
  };

  push = (extendedPath: String = "") => {
    if (history) {
      history.push(`${this.toString()}${extendedPath??''}`);
    }
  };

  reload = () => {
    window.location.href = this.toString();
  }

  makePath = (path: Function): Function => {
    return (...args: any[]) => {
      let pathMethod = typeof path === "string" ? () => path : path;
      let absolutePath = null;

      if (last(args) instanceof Object) {
        const params = args.slice(0, args.length - 1);
        const query = last(args);
        const queryString = qs.stringify(query);

        absolutePath = `${this}/${pathMethod(...params)}?${queryString}`;
      } else {
        absolutePath = `${this}/${pathMethod(...args)}`;
      }

      return new Path(absolutePath.replace(/\/+/, "/"));
    };
  };

  page404Path           = this.makePath(() => `404`)
  page500Path           = this.makePath(() => `500`)

  signInPath            = this.makePath(() => `auth/sign-in`)
  signUpPath            = this.makePath(() => `auth/sign-up`)
  setupPasswordPath     = this.makePath(() => `auth/setup-password`)
  resetPasswordPath     = this.makePath(() => `auth/reset-password`)

  dashboardPath         = this.makePath(() => `dashboard`)

  adminsPath            = this.makePath(() => `admins`)
  adminNewPath          = this.makePath(() => `admins/new`)
  adminEditPath         = this.makePath((id: any) => `admins/${id}/edit`)

  suppliersPath         = this.makePath(() => `suppliers`)
  supplierNewPath       = this.makePath(() => `suppliers/new`)
  supplierEditPath      = this.makePath((id: any) => `suppliers/${id}/edit`)

  bookingsPath                  = this.makePath(() => `bookings`)
  bookingDetailPath             = this.makePath((id: any) => `booking/${id}`)

  supplierBookingsPath          = this.makePath(() => `supplier-bookings`)
  supplierBookingDetailPath     = this.makePath((id: any) => `supplier-booking/${id}`)

  profilePath           = this.makePath(() => `profile`)
  // adminProfilePath      = this.makePath(() => `profile/admin`)
  // supplierProfilePath   = this.makePath(() => `profile/supplier`)
  settingPath           = this.makePath(() => `setting`)

  materialsPath         = this.makePath(() => `materials`)
  materialNewPath       = this.makePath(() => `materials/new`)
  materialEditPath      = this.makePath((id: any) => `materials/${id}/edit`)

  configsPath           = this.makePath(() => `configs`)

  homePath              = this.dashboardPath
}

export default new Path();
