import { compose, withHooks } from "enhancers";
import {
  Box,
  Button,
  BrowseButton,
  Field,
  ImageLightbox,
  Typography,
} from "components";
import ImageUpload from "./ImageUpload";
import { get, range, map } from "lodash";
import styled from "styled-components/macro";

import { ReactComponent as AddImageIcon } from "assets/icon/add_image.svg";

const DeleteButton = styled(Button)({
  "&.MuiButton-contained": {
    "& path": {
      fill: "#F34336",
    },
    "& svg": {
      width: 20,
      height: 20,
    },
  },
});

const ImageUploadList = (props) => (
  <Box {...props}>
    <Box display="flex" flex={1}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flex={1}
        mt={6}
      >
        <Typography variant="body1" fontWeight="bold">
          {props.caption}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        <BrowseButton
          startIcon={<AddImageIcon />}
          onBrowse={props.addImage}
          mt={6}
          // width={137}
          accept="image/*"
          style={{
            borderColor: "#233044",
          }}
          disabled={!props.canAddMore}
        >
          {props.addButtonLabel}
        </BrowseButton>
      </Box>
    </Box>
    <Box
      display="flex"
      style={{
        overflow: "hidden",
        overflowX: "auto", // added scroll
      }}
    >
      {range(props.amount).map((index) => {
        return (
          <Box key={index} mt={6} mr={6}>
            <Box onClick={() => props.openImageLightbox(index)}>
              <Field component={ImageUpload} name={`${props.name}[${index}]`} />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              mt={2.5}
            >
              {props.label ? props.label(index) : null}
              <DeleteButton
                startIcon="redTrash"
                style={{
                  minWidth: 32,
                  paddingLeft: 0,
                  paddingRight: 0,
                  justifyContent: "center",
                }}
                onClick={() => props.removeImage(index)}
              />
            </Box>
          </Box>
        );
      })}
    </Box>
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      form,
      name,
      insert,
      remove,
      title,
      addButtonLabel,
      mt,
      label,
      onRemoveImage,
      max,
      caption,
    } = props;
    const { useCallback } = hooks;
    const values = get(form.values, name);
    const amount = values?.length ?? 0;

    const canAddMore = amount < max;
    const addImage = useCallback(
      (file) => {
        if (canAddMore) {
          insert(amount, file);
        }
      },
      [insert, amount, canAddMore]
    );

    const removeImage = useCallback(
      (index) => {
        remove(index);
        if (onRemoveImage) {
          onRemoveImage(index, props);
        }
      },
      [remove, onRemoveImage, props]
    );

    const openImageLightbox = useCallback(
      (index) => {
        const images = map(values, (file, index) => {
          return {
            url: file instanceof File ? URL.createObjectURL(file) : null,
            title: `รูปที่ ${index + 1}`,
          };
        });
        ImageLightbox.open({
          images,
          startIndex: index,
        });
      },
      [values]
    );

    return {
      caption,
      name,
      amount,
      addImage,
      removeImage,
      title,
      addButtonLabel,
      mt,
      label,
      canAddMore,
      openImageLightbox,
    };
  })
);

export default enhancer(ImageUploadList);
