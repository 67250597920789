import { compose, withHooks, withFormik } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Typography,
  Form,
  Field,
  TextField,
  Button,
  Notification,
  Select,
  Grid,
  Editor,
  Divider,
  ImageUploadList,
  FieldArray,
  SwitchInput,
} from "components";
import { getErrorMessage, gql, notifyError, paths } from "utils/helper";
import { isEqual, map } from "lodash";

const MaterialEditPage = (props) => (
  <PageContent
    title={props.code}
    breadcrumbs={[
      { path: paths.materialsPath(), label: "วัสดุ" },
      { path: null, label: props.code },
    ]}
  >
    <Form>
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          flex={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4">ข้อมูลทั่วไป</Typography>
          <Field
            component={SwitchInput}
            name="availableStatus"
            label="แสดงในผลการค้นหา"
          />
        </Box>
        <FieldArray
          component={ImageUploadList}
          caption="รูปภาพวัสดุ"
          name="images"
          addButtonLabel="เพิ่มรูปวัสดุ"
          max={7}
          // onRemoveImage={(removedIndex, props) => {
          //   const currentIndex = parseInt(
          //     props.form.values.highlightImagesIndex
          //   );
          //   if (!isNaN(currentIndex)) {
          //     if (removedIndex < currentIndex) {
          //       props.form.setFieldValue(
          //         "highlightImagesIndex",
          //         `${currentIndex - 1}`
          //       );
          //     } else if (removedIndex === currentIndex) {
          //       props.form.setFieldValue("highlightImagesIndex", null);
          //     }
          //   }
          // }}
        />
        <Box display="flex" flex={1}>
          <Box display="flex" flexDirection="column" flex={1} mt={6} mr={6}>
            <Field
              component={TextField}
              name="name"
              type="text"
              label="ชื่อ"
              required
            />
          </Box>
          <Box display="flex" flexDirection="column" flex={1} mt={6} ml={6}>
            <Field
              component={Select}
              name="type"
              label="ประเภท"
              fullWidth
              options={props.fabricTypesOptions}
              forceFix
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flex={1} mt={6}>
          <Field
            component={TextField}
            name="description"
            type="text"
            label="รายละเอียดวัสดุสำหรับแสดงให้ช่างและลูกค้า"
            required
            fullWidth
            multiline
            className="height-136"
          />
        </Box>
        <Box display="flex" flex={1}>
          <Box display="flex" flexDirection="column" flex={1} mt={6} mr={6}>
            <Field
              component={Select}
              name="unit"
              type="text"
              label="หน่วย"
              options={props.materialUnitsOptions}
              required
              forceFix
            />
          </Box>
          <Box display="flex" flexDirection="column" flex={1} mt={6} ml={6}>
            <Field
              component={TextField}
              name="pricePerUnit"
              type="number"
              label="ราคาต่อหน่วย"
              required
            />
          </Box>
        </Box>
        <Typography variant="h4" mt={8}>
          บันทึกเพิ่มเติม
        </Typography>
        <Grid container spacing={6} mt={2}>
          <Grid item xs={12}>
            <Field component={Editor} name="remark" label="แบรนด์" fullWidth />
          </Grid>
        </Grid>
        <Typography variant="body" mt={8} color="Text/Dark Grey">
          *บันทึกเพิ่มเติมสำหรับทีมงานจะไม่ถูกแสดงให้ช่างหรือลูกค้าเห็น
        </Typography>
        <Divider mt={10} />
        <Button
          type="submit"
          color="secondary"
          mt={6}
          width={74}
          // disabled={props.disabledSubmitButton}
        >
          บันทึก
        </Button>
      </Box>
    </Form>
  </PageContent>
);

export const API = {
  FETCH_CONFIGS: gql`
    query FETCH_CONFIGS {
      config {
        detail
      }
    }
  `,
  FETCH_MATERIAL: gql`
    query FETCH_MATERIAL($id: String!) {
      material(id: $id) {
        code
        name
        type
        images {
          url
          filename
        }
        availableStatus
        description
        unit
        pricePerUnit
        remark
      }
    }
  `,
  UPDATE_MATERIAL: gql`
    mutation UPDATE_MATERIAL(
      $id: String
      $code: String
      $name: String
      $type: String
      $images: [Upload!]
      $availableStatus: Boolean
      $description: String
      $unit: String
      $pricePerUnit: String
      $remark: String
    ) {
      editMaterialInfo(
        input: {
          id: $id
          code: $code
          name: $name
          type: $type
          images: $images
          availableStatus: $availableStatus
          description: $description
          unit: $unit
          pricePerUnit: $pricePerUnit
          remark: $remark
        }
      ) {
        code
        name
        type
        images {
          url
          filename
        }
        availableStatus
        description
        unit
        pricePerUnit
        remark
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: () => ({
      code: "",
      name: "",
      type: "",
      availableStatus: false,
      description: "",
      unit: "",
      pricePerUnit: null,
      materialRemark: "",
      images: null,
    }),
  }),
  withHooks((props, hooks) => {
    const { setValues, values } = props;
    const {
      useQuery,
      useMutation,
      useMemo,
      useHandleSubmit,
      useEffect,
      useParams,
    } = hooks;
    const { id } = useParams();

    const { loading, data, error, refetch } = useQuery(API.FETCH_MATERIAL, {
      variables: { id },
    });
    const {
      loading: configsLoading,
      data: configsData,
      error: configsError,
      refetch: configsRefetch,
    } = useQuery(API.FETCH_CONFIGS);
    const [updateMaterial] = useMutation(API.UPDATE_MATERIAL);

    useEffect(() => {
      refetch();
    }, [refetch]);

    useEffect(() => {
      configsRefetch();
    }, [configsRefetch]);

    const material = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.materialsPath().push();
        return null;
      }
      return data.material;
    }, [loading, data, error]);

    useEffect(() => {
      const customMaterial = {
        ...material,
        remark: material?.remark,
      };
      setValues(customMaterial);
    }, [setValues, material]);

    const configs = useMemo(() => {
      if (configsLoading || configsError) {
        return null;
      }
      return JSON.parse(configsData?.config?.detail);
    }, [configsLoading, configsData, configsError]);

    const fabricTypesOptions = useMemo(() => {
      if (!configs) return;

      const customMaterialTypes = map(configs?.materialTypes, (type) => {
        if (type.value === null || type.value === undefined) {
          return {
            ...type,
            value: type.label,
          };
        } else {
          return type;
        }
      });

      return customMaterialTypes || [];
    }, [configs]);

    const materialUnitsOptions = useMemo(() => {
      if (!configs) return;

      const customMaterialUnits = map(configs?.materialUnits, (unit) => {
        if (unit.value === null || unit.value === undefined) {
          return {
            ...unit,
            value: unit.label,
          };
        } else {
          return unit;
        }
      });

      return customMaterialUnits || [];
    }, [configs]);

    useHandleSubmit(
      async (values) => {
        try {
          await updateMaterial({
            variables: {
              ...values,
              id: id,
              pricePerUnit: values.pricePerUnit.toString(),
            },
          });
          refetch();
          Notification.success("แก้ไขข้อมูลสำเร็จ");
        } catch (e) {
          Notification.error(e.toString());
        }
      },
      [updateMaterial, id, refetch]
    );

    const disabledSubmitButton = useMemo(
      () => isEqual(values, data?.material),
      [values, data]
    );

    return {
      code: material?.code,
      disabledSubmitButton,
      materialUnitsOptions,
      fabricTypesOptions,
    };
  })
);

export default enhancer(MaterialEditPage);
