import "./wdyr";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import "./mocks";

import { Provider } from "react-redux";

import { ApiProvider } from "api";

import { HelmetProvider, Helmet } from "react-helmet-async";
import DateFnsUtils from "@date-io/date-fns";

import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";

import createTheme from "theme";

import { THEMES } from "./constants";

import {
  BrowserRouter,
  Modal,
  Notification,
  ExcelGenerator,
  ImageLightbox,
} from "components";
import store from "stores";

import Routes from "routes/Routes";

import { SnackbarProvider } from "notistack";
import { withHooks } from "enhancers";
import { useMediaQuery } from "@material-ui/core";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")!,
});

const customThemeProviderEnhancer = withHooks((props: any, hooks: any) => {
  const { useMemo } = hooks;
  const isMobileSize = useMediaQuery("(max-width: 375px)");

  const theme = useMemo(() => {
    return isMobileSize
      ? createTheme(THEMES.MOBILE)
      : createTheme(THEMES.DEFAULT);
  }, [isMobileSize]);

  return { ...props, theme };
});

const customMuiThemeProviderEnhancer = withHooks((props: any, hooks: any) => {
  const { useMemo } = hooks;
  const isMobileSize = useMediaQuery("(max-width: 375px)");

  const theme = useMemo(() => {
    return isMobileSize
      ? createTheme(THEMES.MOBILE)
      : createTheme(THEMES.DEFAULT);
  }, [isMobileSize]);

  return { ...props, theme };
});

const CustomMuiThemeProvider = customMuiThemeProviderEnhancer(MuiThemeProvider);
const CustomThemeProvider = customThemeProviderEnhancer(ThemeProvider);

ReactDOM.render(
  <Provider store={store}>
    <ApiProvider>
      <HelmetProvider>
        <Helmet titleTemplate="%s | Fabrix Admin" defaultTitle="Welcome" />
        <StylesProvider jss={jss}>
          <SnackbarProvider maxSnack={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <CustomMuiThemeProvider>
                <CustomThemeProvider>
                  <BrowserRouter>
                    <Routes />
                    <Modal storeName="appStore.mainModal" />
                    <Modal storeName="appStore.alertModal" />
                    <Modal storeName="appStore.confirmModal" />
                    <ImageLightbox storeName="appStore.imageLightbox" />
                    <Notification />
                    <ExcelGenerator storeName="appStore.excelGenerator" />
                  </BrowserRouter>
                </CustomThemeProvider>
              </CustomMuiThemeProvider>
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </StylesProvider>
      </HelmetProvider>
    </ApiProvider>
  </Provider>,
  document.getElementById("root")
);
