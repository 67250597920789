import React, { useEffect } from "react";
import { compose, withHooks } from "enhancers";
import { useQuery, gql } from "@apollo/client";
import paths from "routes/paths";

import appStore from "stores/appStore";

import { Switch, Route, Redirect } from "components";
import { BlankLayout, MenuLayout } from "layouts";

import SignInPage from "pages/auth/SignIn";
import SignUpPage from "pages/auth/SignUp";
import SetupPasswordPage from "pages/auth/SetupPassword";
import ResetPasswordPage from "pages/auth/ResetPassword";

import DashboardPage from "pages/main/dashboard";

import AdminIndexPage from "pages/main/admins/index";
import AdminNewPage from "pages/main/admins/new";
import AdminEditPage from "pages/main/admins/edit";

import SupplierIndexPage from "pages/main/suppliers/index";
import SupplierNewPage from "pages/main/suppliers/new";
import SupplierEditPage from "pages/main/suppliers/edit";

import BookingIndexPage from "pages/main/bookings/admin/index";
import BookingDetailPage from "pages/main/bookings/admin/detail";

import SupplierBookingIndexPage from "pages/main/bookings/supplier/index";
import SupplierBookingDetailPage from "pages/main/bookings/supplier/detail";

import MaterialIndexPage from "pages/main/materials/index";
import MaterialNewPage from "pages/main/materials/new";
import MaterialEditPage from "pages/main/materials/edit";

import ConfigPage from "pages/main/configs/index";

import ProfilePage from "pages/main/profile";

// prettier-ignore
const InitialPages = () => (
  <BlankLayout>
    <Switch>
      <Route path={paths.signUpPath()}          exact component={SignUpPage}        />
      <Route path={paths.setupPasswordPath()}   exact component={SetupPasswordPage} />

      <Redirect to={paths.signUpPath()} />
    </Switch>
  </BlankLayout>
)

// prettier-ignore
const GuestPages = () => (
  <BlankLayout>
    <Switch>
      <Route path={paths.signInPath()}         exact component={SignInPage}        />
      <Route path={paths.setupPasswordPath()}  exact component={SetupPasswordPage} />
      <Route path={paths.resetPasswordPath()}  exact component={ResetPasswordPage} />

      <Redirect to={paths.signInPath()} />
    </Switch>
  </BlankLayout>
)

// prettier-ignore
const MainPages = (props: any) => (
  <Switch>
    <Route path={paths.dashboardPath()}                       exact layout={MenuLayout}  component={DashboardPage}          />

    <Route path={paths.adminsPath()}                          exact layout={MenuLayout}  component={AdminIndexPage}         />
    <Route path={paths.adminNewPath()}                        exact layout={MenuLayout}  component={AdminNewPage}           />
    <Route path={paths.adminEditPath(':id')}                  exact layout={MenuLayout}  component={AdminEditPage}          />

    <Route path={paths.suppliersPath()}                       exact layout={MenuLayout}  component={SupplierIndexPage}      />
    <Route path={paths.supplierNewPath()}                     exact layout={MenuLayout}  component={SupplierNewPage}        />
    <Route path={paths.supplierEditPath(':id')}               exact layout={MenuLayout} component={SupplierEditPage}        />

    <Route path={paths.bookingsPath()}                        exact layout={MenuLayout} component={BookingIndexPage}        />
    <Route path={paths.bookingDetailPath(':id')}              exact layout={MenuLayout} component={BookingDetailPage} />

    <Route path={paths.supplierBookingsPath()}               exact layout={MenuLayout} component={SupplierBookingIndexPage}        />
    <Route path={paths.supplierBookingDetailPath(':id')}     exact layout={MenuLayout} component={SupplierBookingDetailPage}       />

    // TODO: Remove when admin and supplier profiles are ready
    <Route path={paths.profilePath()}                         exact layout={MenuLayout} component={ProfilePage}            />
    {/* <Route path={paths.adminProfilePath()}                    exact layout={MenuLayout} component={AdminProfilePage}            />
    <Route path={paths.supplierProfilePath()}                 exact layout={MenuLayout} component={SupplierProfilePage}            /> */}

    <Route path={paths.materialsPath()}                       exact layout={MenuLayout} component={MaterialIndexPage}       />
    <Route path={paths.materialNewPath()}                     exact layout={MenuLayout} component={MaterialNewPage}         />
    <Route path={paths.materialEditPath(':id')}               exact layout={MenuLayout} component={MaterialEditPage} />

    <Route path={paths.configsPath()}                         exact layout={MenuLayout} component={ConfigPage}        />

    <Redirect to={props.isAdmin ? paths.bookingsPath() : paths.supplierBookingsPath()} />
  </Switch>
)

interface RoutesProps {
  initialized: boolean;
  hasFirstAdmin: boolean;
  isAuthorized: boolean;
  isAdmin: boolean;
}

// prettier-ignore
const Routes = (props: RoutesProps) => {
       if (!props.initialized)   { return null;             }
  else if (!props.hasFirstAdmin) { return <InitialPages />; }
  else if (!props.isAuthorized)  { return <GuestPages />;   }
  else                           { return <MainPages isAdmin={props.isAdmin}/>;    }
};

export const API = {
  GET_APP_INFO: gql`
    query GET_APP_INFO {
      info {
        hasFirstAdmin
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      currentUser {
        email
        firstName
        lastName
        code
        avatarUrl
        ownerType
        admin {
          code
          isSuperAdmin
        }
        supplier {
          code
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useQuery, useMemo, useEffect } = hooks;

    const appInfo = useQuery(API.GET_APP_INFO);
    const currentUser = useQuery(API.GET_CURRENT_USER, {
      onCompleted: (data: any) => {
        appStore.setCurrentUser(data.currentUser);
      },
    });

    const { loading, data, error } = currentUser || {};

    const initialized = !appInfo.loading && !currentUser.loading;
    const isAuthorized = !currentUser.error;
    const hasFirstAdmin = !!appInfo.data?.info?.hasFirstAdmin;

    const ownerType = useMemo(() => {
      if (loading || error) {
        return null;
      }
      return data.currentUser?.ownerType;
    }, [loading, data, error]);

    // const admin = useMemo(() => {
    //   if (loading || error) {
    //     return null;
    //   }
    //   return data.currentUser?.admin;
    // }, [loading, data, error]);

    // const supplier = useMemo(() => {
    //   if (loading || error) {
    //     return null;
    //   }
    //   return data.currentUser?.supplier;
    // }, [loading, data, error]);

    useEffect(() => {
      const $splashScreen = document.getElementById("splash-screen");
      if ($splashScreen) {
        const display = initialized ? "none" : "";
        $splashScreen.style.display = display;
      }
    }, [initialized]);

    const isAdmin = ownerType === "Admin";

    return {
      initialized,
      isAuthorized,
      hasFirstAdmin,
      isAdmin,
    };
  })
);

export default enhancer(Routes);
