import { compose, withHooks } from "enhancers";
import { Typography, Box } from "components";
import { formatDate, toCurrency } from "utils/helper";
import { differenceInDays } from "date-fns";
import { join } from "lodash";
import { ReactComponent as Warning } from "assets/icon/warning.svg";

const ConfirmModalDetail = (props) => (
  <Box flex={1} mb={5} display="flex" flexDirection="column">
    <Typography variant="body1" color="black" fontWeight={600}>
      ยืนยันการจองคิว
    </Typography>
    <Box flex={1} display="flex" flexDirection="column" mt={2}>
      <Typography variant="body1" color="#767676">
        ระบบจะทำการสร้างคำสั่งซื้อโดยใช้ข้อมูลจากการจองคิวหมายเลข
      </Typography>
      <Typography variant="body1" color="black" fontWeight={600}>
        {props.booking?.code}
      </Typography>
    </Box>
    <Box
      flex={1}
      mt={4}
      display="flex"
      flexDirection="column"
      bgcolor="#E7F4FD"
      padding={4}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        mb={1}
        mt={1}
      >
        <Typography variant="body1" width={130}>
          ประเภทการตัดเย็บ
        </Typography>
        <Typography variant="body1" width={24}>
          :
        </Typography>
        <Typography variant="body1" flex={1}>
          {props.booking?.productType ?? "-"}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        mb={1}
        mt={1}
      >
        <Typography variant="body1" width={130}>
          เกรดงาน
        </Typography>
        <Typography variant="body1" width={24}>
          :
        </Typography>
        <Typography variant="body1" flex={1}>
          {props.booking?.productGrade}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        mb={1}
        mt={1}
      >
        <Typography variant="body1" width={130}>
          จำนวนรวม
        </Typography>
        <Typography variant="body1" width={24}>
          :
        </Typography>
        <Typography variant="body1" flex={1}>
          {props.booking?.productAmout}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        mb={1}
        mt={1}
      >
        <Typography variant="body1" width={130}>
          วันที่ต้องการรับของ
        </Typography>
        <Typography variant="body1" width={24}>
          :
        </Typography>
        <Typography variant="body1" flex={1}>
          {`${formatDate(
            props.booking?.productReceivedDueDate,
            "dd/MM/yyyy"
          )} (${differenceInDays(
            new Date(props.booking?.productReceivedDueDate),
            new Date()
          )} วัน)`}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        mb={1}
        mt={1}
      >
        <Typography variant="body1" width={130}>
          การตกแต่ง
        </Typography>
        <Typography variant="body1" width={24}>
          :
        </Typography>
        <Typography variant="body1" flex={1}>
          {props.productDescription ?? "-"}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        mb={1}
        mt={1}
      >
        <Typography variant="body1" width={130}>
          ราคาประเมิน
        </Typography>
        <Typography variant="body1" width={24}>
          :
        </Typography>
        <Typography variant="body1" flex={1}>
          {`${toCurrency(props.booking?.minEstimatedCost) ?? 0} ถึง ${
            toCurrency(props.booking?.maxEstimatedCost) ?? 0
          } บาท`}
        </Typography>
      </Box>
    </Box>
    {props.booking?.remark && (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        bgcolor="#FFF4E5"
        padding={2.5}
        mt={4}
        borderRadius={4}
      >
        <Warning />
        <Typography variant="body1" color="#FF9800" ml={2}>
          {props.booking?.remark}
        </Typography>
      </Box>
    )}
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo } = hooks;
    const { booking } = props;

    const productDescription = useMemo(() => {
      const productString = join(booking?.productDescription, "/");
      return productString;
    }, [booking]);

    return {
      booking,
      productDescription,
    };
  })
);

export default enhancer(ConfirmModalDetail);
