import React from "react";
import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography, Modal, ExcelGenerator } from "components";
import {
  gql,
  notifyError,
  notifySuccess,
  paths,
  formatDate,
} from "utils/helper";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as RedTrashIcon } from "assets/icon/red_trash.svg";
import { ReactComponent as DownloadIcon } from "assets/icon/download-icon-white.svg";

const MaterialIndexPage = (props) => (
  <PageContent
    title="วัสดุ"
    pageActions={
      props.currentUserIsAdmin
        ? [
            {
              children: "Export",
              startIcon: <DownloadIcon />,
              onClick: props.downloadProducts,
              color: "primary",
            },
            {
              children: "เพิ่มวัสดุ",
              startIcon: <AddIcon />,
              onClick: () => paths.materialNewPath().push(),
              color: "primary",
            },
          ]
        : [
            {
              children: "Export",
              startIcon: <DownloadIcon />,
              onClick: props.downloadProducts,
              color: "primary",
            },
          ]
    }
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายชื่อวัสดุ
      </Typography>
      <Table
        columns={[
          {
            width: 32,
            field: "availableStatus",
            type: "available",
            headerName: " ",
          },
          {
            width: 110,
            field: "code",
            headerName: "รหัส",
          },
          {
            width: 200,
            field: "name",
            headerName: "ชื่อ",
          },
          {
            width: 200,
            field: "type",
            headerName: "ประเภท",
          },
          {
            width: 440,
            field: "description",
            headerName: "รายละเอียด",
          },
          {
            width: 55,
            field: "actions",
            headerName: " ",
            filterable: false,
            sortable: false,
            type: "actions",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.materialEditPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
        // includeToolbar
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_MATERIALS: gql`
    query FETCH_MATERIALS {
      materials {
        id
        code
        name
        type
        description
        availableStatus
      }
      currentUser {
        ownerType
        admin {
          isSuperAdmin
        }
      }
    }
  `,
  DELETE_MATERIALS: gql`
    mutation DELETE_MATERIALS($ids: [String!]!) {
      deleteMaterials(input: { ids: $ids }) {
        materials {
          id
          code
          name
          type
          description
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useCallback, useQuery, useMutation, useEffect } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_MATERIALS);
    const [deleteMaterials] = useMutation(API.DELETE_MATERIALS);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const deleteAdmin = useCallback(
      async (params) => {
        const { id, name } = params.row;

        Modal.open({
          title: "ลบข้อมูลวัสดุ",
          children: (
            <Box flex={1} mb={5} display="flex" flexDirection="row">
              <Typography variant="body1" color="#767676">
                ต้องการลบข้อมูลวัสดุ
              </Typography>
              <Box ml={1}>
                <Typography variant="body1" color="black" fontWeight={600}>
                  {name}
                </Typography>
              </Box>
            </Box>
          ),
          cancelButtonLabel: "ยกเลิก",
          okButtonLabel: "ลบ",
          onOk: async ({ close }) => {
            try {
              await deleteMaterials({ variables: { ids: [id] } });
              await refetch();
              close();
              notifySuccess("ลบข้อมูลสำเร็จ");
            } catch (e) {
              notifyError(e);
            }
          },
        });
      },
      [deleteMaterials, refetch]
    );

    const currentUserIsSuperAdmin = data?.currentUser?.admin?.isSuperAdmin;
    const currentUserIsAdmin = data?.currentUser?.ownerType;

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.materials.map((material, index) => {
        return {
          ...material,
          actions: currentUserIsSuperAdmin
            ? [
                {
                  Icon: RedTrashIcon,
                  onClick: deleteAdmin,
                  can: currentUserIsSuperAdmin,
                },
              ]
            : [],
        };
      });
    }, [loading, data, error, deleteAdmin, currentUserIsSuperAdmin]);

    const excelData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      const materials = data.materials.map((material) => {
        const status = material.availableStatus ? "ใช้งาน" : "ไม่ใช้งาน";

        return {
          ...material,
          availableStatus: status,
        };
      });

      return materials;
    }, [loading, data, error]);

    const downloadProducts = useCallback(async () => {
      ExcelGenerator.generate({
        fileName: `materials-${formatDate(new Date(), "yyyyMMddhhmmss")}`,
        columns: [
          {
            title: "สถานะ",
            field: "availableStatus",
          },
          {
            title: "รหัส",
            field: "code",
          },
          {
            title: "ชื่อ",
            field: "name",
          },
          {
            title: "ประเภท",
            field: "type",
          },
          {
            title: "รายละเอียด",
            field: "description",
          },
        ],
        data: excelData,
      });
    }, [excelData]);

    return {
      downloadProducts,
      tableData,
      loading,
      currentUserIsSuperAdmin,
      currentUserIsAdmin,
    };
  })
);

export default enhancer(MaterialIndexPage);
