import React from "react";
import { compose, withHooks, withFormik } from "enhancers";
import styled from "styled-components/macro";
import {
  Form,
  Field,
  TextField,
  Button,
  Alert,
  Avatar,
  Paper,
  Typography,
  Helmet,
  Notification,
  Box,
} from "components";
import { gql, Yup, paths } from "utils/helper";
import { ReactComponent as Logo } from "assets/image/logo.svg";
import { useMediaQuery } from "@material-ui/core";

const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

type SetupPasswordPageProps = {
  errorMessage?: string;
  email: string;
  moreInfo: boolean;
  isMobileSize: boolean;
};

const SetupPasswordPage = (props: SetupPasswordPageProps) => (
  <Paper
    width="100%"
    py={props.isMobileSize ? 6 : 10}
    px={props.isMobileSize ? 4 : 10}
    style={{ alignSelf: props.isMobileSize ? "flex-start" : "center" }}
  >
    <Helmet title="Setup Password" />
    {/* <Avatar
      alt="Fabrix"
      src="/static/img/fabrix_logo.jpg"
      variant="square"
      mx="auto"
    /> */}
    <LogoContainer>
      <Logo></Logo>
    </LogoContainer>
    <Typography component="h1" variant="h4" align="center" mt={10}>
      สร้างรหัสผ่านสำหรับ
    </Typography>
    <Typography component="h1" variant="h4" align="center">
      {props.email}
    </Typography>
    <Box
      display="flex"
      flexDirection={props.isMobileSize ? "column" : "row"}
      mt={2}
      justifyContent="center"
      alignItems="center"
    >
      <Typography component="h2" variant="caption">
        รหัสผ่านต้องมี 8-10 ตัวอักษร
      </Typography>
      <Typography component="h2" variant="caption">
        ประกอบด้วยตัวอักษรภาษาอังกฤษและตัวเลข
      </Typography>
    </Box>
    <Form>
      {props.errorMessage && (
        <Alert severity="error" mt={6}>
          {props.errorMessage}
        </Alert>
      )}
      <Field
        component={TextField}
        name="password"
        type="password"
        label="รหัสผ่าน"
        fullWidth
        mt={6}
      />
      <Field
        component={TextField}
        name="confirmPassword"
        type="password"
        label="ยืนยันรหัสผ่าน"
        fullWidth
        mt={6}
      />

      {props.moreInfo && (
        <>
          <Typography component="h1" variant="h4" align="center" mt={10}>
            ข้อมูลเพิ่มเติม
          </Typography>
          <Typography component="h2" variant="caption" align="center" mt={2}>
            กรอกชื่อ-นามสกุลเพื่อใช้แสดงในหน้าโปรไฟล์
          </Typography>
          <Field
            component={TextField}
            name="firstName"
            type="text"
            label="ชื่อ"
            fullWidth
            required
            mt={6}
          />
          <Field
            component={TextField}
            name="lastName"
            type="text"
            label="นามสกุล"
            fullWidth
            required
            mt={6}
          />
        </>
      )}

      <Button type="submit" color="secondary" fullWidth mt={10}>
        สร้างรหัสผ่าน
      </Button>
    </Form>
  </Paper>
);

const API = {
  SETUP_PASSWORD: gql`
    mutation SETUP_PASSWORD(
      $invitationToken: String!
      $password: String!
      $firstName: String
      $lastName: String
    ) {
      setupPassword(
        input: {
          invitationToken: $invitationToken
          password: $password
          firstName: $firstName
          lastName: $lastName
        }
      ) {
        currentUser {
          email
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
    }),
    validationSchema: (props: any) =>
      Yup.object().shape({
        password: Yup.string()
          .min(8, "รหัสผ่านต้องไม่น้อยกว่า 8 ตัวอักษร")
          .max(10, "รหัสผ่านต้องน้อยกว่าหรือเท่ากับ 10 ตัวอักษร")
          .matches(
            /^\w*[A-z]\w*[0-9]\w*$|^\w*[0-9]\w*[A-z]\w*$/,
            "รูปแบบรหัสผ่านไม่ถูกต้อง"
          ),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref("password")],
          "รหัสผ่านไม่ตรงกัน"
        ),
        ...(props.moreInfo
          ? {
              firstName: Yup.string().required("ชื่อต้องไม่เว้นว่างเอาไว้"),
              lastName: Yup.string().required("นามสกุลต้องไม่เว้นว่างเอาไว้"),
            }
          : null),
      }),
  }),
  withHooks((props: any, hooks: any) => {
    const { setErrors } = props;
    const {
      useHandleSubmit,
      useMutation,
      useUrlParam,
      usePropsToFormikBag,
    } = hooks;

    const { token: invitationToken, email, moreInfo } = useUrlParam({
      moreInfo: "boolean",
    });

    const [setupPassword] = useMutation(API.SETUP_PASSWORD);

    usePropsToFormikBag(() => {
      return { moreInfo };
    }, [moreInfo]);

    useHandleSubmit(
      async (values: any) => {
        try {
          const { password, firstName, lastName } = values;

          if (password) {
            await setupPassword({
              variables: { invitationToken, password, firstName, lastName },
            });
            Notification.success("สร้างรหัสผ่านสำเร็จ");
            paths.signInPath().push();
          } else {
            setErrors({ password: "ต้องไม่เว้นว่างไว้" });
          }
        } catch (error: any) {
          console.error(error);
          const errorMessage =
            error?.networkError?.result?.errors?.map!((e: any) => e.message)
              ?.join!(", ") ||
            error?.networkError?.result?.message ||
            "เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน";
          Notification.error(errorMessage);
        }
      },
      [setupPassword, invitationToken]
    );

    const isMobileSize = useMediaQuery("(max-width: 375px)");

    return {
      email,
      moreInfo,
      isMobileSize,
    };
  })
);

export default enhancer(SetupPasswordPage);
