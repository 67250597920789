import React from "react";
import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography, Modal, ExcelGenerator } from "components";
import {
  gql,
  notifyError,
  notifySuccess,
  paths,
  formatDate,
} from "utils/helper";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as RedTrashIcon } from "assets/icon/red_trash.svg";
import { ReactComponent as RedTrashIconOpacity } from "assets/icon/red_trash_opacity.svg";
import { ReactComponent as DownloadIcon } from "assets/icon/download-icon-white.svg";

const SupplierIndexPage = (props) => (
  <PageContent
    title="ช่าง"
    pageActions={
      props.currentUserIsAdmin
        ? [
            {
              children: "Export",
              startIcon: <DownloadIcon />,
              onClick: props.downloadProducts,
              color: "primary",
            },
            {
              children: "เพิ่มช่าง",
              startIcon: <AddIcon />,
              onClick: () => paths.supplierNewPath().push(),
              color: "primary",
            },
          ]
        : [
            {
              children: "Export",
              startIcon: <DownloadIcon />,
              onClick: props.downloadProducts,
              color: "primary",
            },
          ]
    }
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายชื่อช่าง
      </Typography>
      <Table
        columns={[
          {
            width: 32,
            field: "availableStatus",
            type: "available",
            headerName: " ",
          },
          {
            width: 100,
            field: "code",
            headerName: "รหัส",
          },
          {
            width: 176,
            field: "fullName",
            headerName: "ชื่อ นามสกุล",
            // filterable: false,
          },
          {
            width: 140,
            field: "phoneNumber",
            headerName: "เบอร์โทรศัพท์",
            type: "phoneNumber",
          },
          { width: 200, field: "email", headerName: "อีเมล" },
          { width: 115, field: "line", headerName: "ไลน์" },
          {
            width: 110,
            field: "supplierType",
            headerName: "ประเภท",
          },
          {
            width: 110,
            field: "productAffordedGradesString",
            headerName: "เกรดงาน",
          },
          {
            width: 40,
            field: "actions",
            headerName: " ",
            filterable: false,
            sortable: false,
            type: "actions",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.supplierEditPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
        // includeToolbar
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_SUPPLIERS: gql`
    query FETCH_SUPPLIERS {
      suppliers {
        code
        email
        firstName
        lastName
        id
        line
        phoneNumber
        availableStatus
        productAffordedGradesString
        supplierType
        canDelete
      }
      currentUser {
        ownerType
        admin {
          isSuperAdmin
        }
      }
    }
  `,
  DELETE_SUPPLIER: gql`
    mutation DELETE_SUPPLIER($ids: [String!]!) {
      deleteSupplier(input: { ids: $ids }) {
        suppliers {
          code
          email
          firstName
          lastName
          id
          line
          phoneNumber
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useCallback, useQuery, useMutation, useEffect } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_SUPPLIERS);
    const [deleteSupplier] = useMutation(API.DELETE_SUPPLIER);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const deleteAdmin = useCallback(
      async (params) => {
        const { id, email } = params.row;

        Modal.open({
          title: "ลบข้อมูลช่าง",
          children: (
            <Box flex={1} mb={5} display="flex" flexDirection="row">
              <Typography variant="body1" color="#767676">
                ต้องการลบข้อมูลช่าง
              </Typography>
              <Box ml={1}>
                <Typography variant="body1" color="black" fontWeight={600}>
                  {email}
                </Typography>
              </Box>
            </Box>
          ),
          cancelButtonLabel: "ยกเลิก",
          okButtonLabel: "ลบข้อมูล",
          onOk: async ({ close }) => {
            try {
              await deleteSupplier({ variables: { ids: [id] } });
              await refetch();
              close();
              notifySuccess("ลบข้อมูลสำเร็จ");
            } catch (e) {
              notifyError(e);
            }
          },
        });
      },
      [deleteSupplier, refetch]
    );

    const currentUserIsSuperAdmin = data?.currentUser?.admin?.isSuperAdmin;
    const currentUserIsAdmin = data?.currentUser?.ownerType;

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.suppliers.map((supplier) => {
        const {
          firstName,
          lastName,
          isSuperAdmin,
          canDelete,
          ...rest
        } = supplier;

        const redTrashIcon = canDelete ? RedTrashIcon : RedTrashIconOpacity;

        return {
          ...rest,
          fullName: `${firstName || ""} ${lastName || ""}`,
          actions: currentUserIsSuperAdmin
            ? [
                {
                  Icon: redTrashIcon,
                  onClick: deleteAdmin,
                  can: !isSuperAdmin,
                  deletedable: canDelete,
                },
              ]
            : [],
        };
      });
    }, [loading, data, error, deleteAdmin, currentUserIsSuperAdmin]);

    const excelData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      const suppliers = data.suppliers.map((supplier) => {
        const status = supplier.availableStatus ? "ใช้งาน" : "ไม่ใช้งาน";

        return {
          ...supplier,
          availableStatus: status,
        };
      });

      return suppliers;
    }, [loading, data, error]);

    const downloadProducts = useCallback(async () => {
      ExcelGenerator.generate({
        fileName: `suppliers-${formatDate(new Date(), "yyyyMMddhhmmss")}`,
        columns: [
          {
            title: "สถานะ",
            field: "availableStatus",
          },
          {
            title: "รหัส",
            field: "code",
          },
          {
            title: "เบอร์โทรศัพท์",
            field: "phoneNumber",
          },
          {
            title: "อีเมล",
            field: "email",
          },
          {
            title: "ไลน์",
            field: "line",
          },
          {
            title: "ประเภท",
            field: "supplierType",
          },
          {
            title: "เกรดงาน",
            field: "productAffordedGradesString",
          },
        ],
        data: excelData,
      });
    }, [excelData]);

    return {
      downloadProducts,
      tableData,
      loading,
      currentUserIsSuperAdmin,
      currentUserIsAdmin,
    };
  })
);

export default enhancer(SupplierIndexPage);
