import React from "react";
import { compose, withHooks, withFormik } from "enhancers";
import styled from "styled-components/macro";
import { gql, Yup } from "utils/helper";
import {
  Form,
  Field,
  TextField,
  Button,
  Alert,
  Avatar,
  Paper,
  Typography,
  Helmet,
  Modal,
  Notification,
  Box,
} from "components";

import { ReactComponent as Logo } from "assets/image/logo.svg";

const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

type SignUpPageProps = {
  warningMessage?: string;
  errorMessage?: string;
};

const SignUpPage = (props: SignUpPageProps) => (
  <Paper width="100%" p={10}>
    <Helmet title="Input Email" />
    {/* <Avatar
      alt="Fabrix"
      src="/static/img/fabrix_logo.svg"
      variant="square"
      mx="auto"
    /> */}
    <LogoContainer>
      <Logo></Logo>
    </LogoContainer>
    <Typography component="h1" variant="h4" align="center" mt={10}>
      ลงทะเบียน
    </Typography>
    <Typography component="h2" variant="caption" align="center" mt={2}>
      กรอกอีเมลเพื่อลงทะเบียนใช้งานระบบ
    </Typography>
    <Form>
      {props.warningMessage && (
        <Alert severity="warning" mt={6}>
          {props.warningMessage}
        </Alert>
      )}
      {props.errorMessage && (
        <Alert severity="error" mt={6}>
          {props.errorMessage}
        </Alert>
      )}
      <Field
        component={TextField.Email}
        name="email"
        label="อีเมล"
        fullWidth
        mt={6}
      />
      <Button type="submit" color="primary" fullWidth mt={10}>
        ลงทะเบียน
      </Button>
    </Form>
  </Paper>
);

const API = {
  REGISTER: gql`
    mutation REGISTER($email: String!) {
      signUp(input: { email: $email }) {
        currentUser {
          email
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({ email: "" }),
    validationSchema: Yup.object().shape({
      email: Yup.string().email("รูปแบบอีเมลไม่ถูกต้อง"),
    }),
  }),
  withHooks((props: any, hooks: any) => {
    const { useMutation, useHandleSubmit } = hooks;
    const [register] = useMutation(API.REGISTER);

    useHandleSubmit(
      async (values: any) => {
        try {
          const { email } = values;
          await register({ variables: { email } });
          Modal.alert({
            title: "ลงทะเบียนสำเร็จ",
            children: `ลิงก์ลงทะเบียนใช้งานระบบได้ถูกส่งไปที่อีเมล ${email} เรียบร้อยแล้ว กรุณาคลิกลิงก์จากอีเมลเพื่อสร้างรหัสผ่านในการเข้าสู่ระบบ`,
            okButtonLabel: "ปิด",
            okButtonVariant: "text",
            onOk: () => window.location.reload(),
            onClose: () => window.location.reload(),
          });
        } catch (error: any) {
          console.error(error);
          const errorMessage =
            error?.networkError?.result?.errors?.map!((e: any) => e.message)
              ?.join!(", ") ||
            error?.networkError?.result?.message ||
            "เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน";
          Notification.error(errorMessage);
        }
      },
      [register]
    );

    return {};
  })
);

export default enhancer(SignUpPage);
