import { useRef } from "react";
import { compose, withHooks, withFormik, defaultProps } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Typography,
  Form,
  Field,
  TextField,
  Button,
  ErrorMessage,
  Notification,
  UploadAvatar,
  Link,
  Modal,
  FieldArray,
  ImageUploadList,
  RadioGroup,
  FileUploadList,
  Editor,
  ExampleProductList,
  Select,
  Address,
  Divider,
} from "components";
import { gql, paths, Yup, notifyError, getErrorMessage } from "utils/helper";
import { removeToken } from "api";
import appStore from "stores/appStore";
import { isEqual, isNil, filter, map } from "lodash";
import {
  subDistrictOptions,
  provinceOptions,
  districtOptions,
  zipCodeOptions,
} from "components/advance/Address/helper";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as CheckedIcon } from "assets/icon/checked.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    "&.MuiPaper-root": { background: "#fff" },
  },
  button: {
    backgroundColor: "#2296F3",
    borderColor: "#2296F3",
    color: "white",
    "&:hover": {
      backgroundColor: "#2296F3",
      borderColor: "#2296F3",
      color: "white",
    },
  },
}));

const SupplierProfilePage = (props) => (
  <PageContent
    title={`โปรไฟล์ - ${props.code}`}
    // breadcrumbs={[
    //   { path: paths.homePath(), label: "หน้าแรก" },
    //   { path: null, label: `โปรไฟล์ - ${props.code}` },
    // ]}
  >
    <Form>
      {!props.isMobileSize ? (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flex={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">ข้อมูลทั่วไป</Typography>
          </Box>
          <ErrorMessage with="100%" name="__error__" mt={6} />
          <Box mt={6}>
            <Box display="flex">
              <Box display="flex" flex={1}>
                <Box display="flex" flexDirection="column" flex={1} mr={6}>
                  <Field
                    component={TextField}
                    name="firstName"
                    type="text"
                    label="ชื่อ"
                    required
                  />
                  <Field
                    component={TextField}
                    name="nickName"
                    type="text"
                    label="ชื่อเล่น"
                    mt={6}
                    required
                  />
                </Box>
                <Box display="flex" flexDirection="column" flex={1} ml={6}>
                  <Field
                    component={TextField}
                    name="lastName"
                    type="text"
                    label="นามสกุล*"
                    required
                  />
                  <Field
                    component={TextField.Email}
                    name="email"
                    label="อีเมล"
                    disabled
                    mt={6}
                  />
                </Box>
              </Box>
            </Box>
            <Box display="flex" mt={10} flex={1}>
              <Typography variant="h4">การติดต่อ</Typography>
            </Box>
            <ErrorMessage with="100%" name="__error__" mt={6} />
            <Box display="flex" flex={1}>
              <Box display="flex" flexDirection="column" flex={1} mt={6} mr={6}>
                <Field
                  component={TextField.PhoneNumber}
                  name="phoneNumber"
                  label="เบอร์โทร"
                  required
                />
              </Box>
              <Box display="flex" flexDirection="column" flex={1} mt={6} ml={6}>
                <Field
                  component={TextField}
                  name="line"
                  type="text"
                  label="LINE ID"
                  // required
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" flex={1} mt={6}>
              <Field
                component={TextField}
                name="addressInfo"
                type="text"
                label="ที่อยู่"
                required
              />
            </Box>
            <Box display="flex" flex={1}>
              <Box display="flex" flexDirection="column" flex={1}>
                <Box
                  display="flex"
                  flexDirection="column"
                  flex={1}
                  mt={6}
                  mr={6}
                >
                  <Field
                    component={Select}
                    name="subDistrict"
                    type="text"
                    label="แขวง/ตำบล"
                    options={subDistrictOptions}
                    transformDisplay={props.transformDisplaySubDistrict}
                    freeSolo
                    fullWidth
                    fuse
                    required
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  flex={1}
                  mt={6}
                  mr={6}
                >
                  <Field
                    component={Select}
                    name="province"
                    type="text"
                    label="จังหวัด"
                    options={provinceOptions}
                    freeSolo
                    fullWidth
                    required
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column" flex={1}>
                <Box
                  display="flex"
                  flexDirection="column"
                  flex={1}
                  mt={6}
                  ml={6}
                >
                  <Field
                    component={Select}
                    name="district"
                    type="text"
                    label="เขต/อำเภอ"
                    options={districtOptions}
                    required
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  flex={1}
                  mt={6}
                  ml={6}
                >
                  <Field
                    component={Select}
                    name="zipcode"
                    type="text"
                    label="รหัสไปรษณีย์"
                    options={zipCodeOptions}
                    freeSolo
                    fullWidth
                    required
                  />
                </Box>
              </Box>
            </Box>
            <Box mt={6}>
              <Typography variant="body" color="Text/Dark Grey">
                *ที่อยู่จะถูกแสดงให้ลูกค้าเห็นถ้าจำเป็นต้องมีการส่งวัสดุมาให้
              </Typography>
            </Box>

            <Box display="flex" mt={10} flex={1}>
              <Typography variant="h4">รายละเอียดงาน</Typography>
            </Box>
            <ErrorMessage with="100%" name="__error__" mt={6} />
            <Box display="flex" flexDirection="column" mt={3} flex={1}>
              <Box display="flex" flexDirection="column" flex={1} mt={6}>
                <Field
                  component={Select}
                  name="supplierType"
                  type=""
                  options={props.supplierTypeOptions}
                  label="ประเภทช่าง"
                  required
                />
              </Box>
              <Box display="flex" flexDirection="column" flex={1} mt={6}>
                <Field
                  component={Select}
                  name="productAffordedGrades"
                  type=""
                  options={props.productAffordedGradeOptions}
                  label="เกรดงานที่ทำได้ (ใส่ได้หลายค่า)"
                  multiple
                  forceFix
                  requiredLabel
                />
              </Box>
              <Box display="flex" flexDirection="column" flex={1} mt={6}>
                <Field
                  component={Select}
                  name="productTypes"
                  type=""
                  options={props.productTypeOptions}
                  label="ประเภทงานที่ถนัด (ใส่ได้หลายค่า)"
                  multiple
                  forceFix
                  requiredLabel
                />
              </Box>
            </Box>
            <Box display="flex" mt={10} flex={1}>
              <Typography variant="h4">ข้อความแนะนำตัว</Typography>
            </Box>
            <Box display="flex" flexDirection="column" flex={1} mt={6}>
              <Field component={Editor} name="greetingText" fullWidth />
            </Box>
            <Box mt={6}>
              <Typography variant="body" color="Text/Dark Grey">
                *ข้อความแนะนำตัวจะถูกแสดงให้ลูกค้าเห็นเมื่อลูกค้ากดดูข้อมูลของคุณ
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column">
              <FieldArray
                component={ExampleProductList}
                name="exampleProducts"
                addButtonLabel="เพิ่มรายการตัวอย่างงาน"
                onRemoveImage={props.deleteExampleProductImage}
              />
            </Box>
            <Divider mt={10} />
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection={props.isMobileSize ? "column" : "row"}
            >
              <Button
                type="submit"
                width={74}
                mt={10}
                className={props.classes.button}
              >
                บันทึก
              </Button>
              <Box mt={10}>
                <Link
                  style={{ cursor: "pointer", color: "#767676" }}
                  onClick={props.logout}
                >
                  ออกจากระบบ
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flex={1}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">ข้อมูลทั่วไป</Typography>
          </Box>
          <ErrorMessage with="100%" name="__error__" mt={6} />
          <Box mt={6}>
            <Box display="flex" flexDirection="column" flex={1}>
              <Field
                component={TextField}
                name="firstName"
                type="text"
                label="ชื่อ"
                required
                mt={6}
              />
              <Field
                component={TextField}
                name="lastName"
                type="text"
                label="นามสกุล*"
                required
                mt={6}
              />
              <Field
                component={TextField}
                name="nickName"
                type="text"
                label="ชื่อเล่น"
                mt={6}
                required
              />
              <Field
                component={TextField.Email}
                name="email"
                label="อีเมล"
                disabled
                mt={6}
              />
            </Box>
            <Box display="flex" mt={10} flex={1}>
              <Typography variant="h4">การติดต่อ</Typography>
            </Box>
            <ErrorMessage with="100%" name="__error__" mt={6} />
            <Box display="flex" flex={1}>
              <Box display="flex" flexDirection="column" flex={1} mt={6}>
                <Field
                  component={TextField.PhoneNumber}
                  name="phoneNumber"
                  label="เบอร์โทร"
                  required
                  mt={6}
                />
                <Field
                  component={TextField}
                  name="line"
                  type="text"
                  label="LINE ID"
                  // required
                  mt={6}
                />
                <Field
                  component={TextField}
                  name="addressInfo"
                  type="text"
                  label="ที่อยู่"
                  required
                  mt={6}
                />
                <Field
                  component={Select}
                  name="subDistrict"
                  type="text"
                  label="แขวง/ตำบล"
                  options={subDistrictOptions}
                  transformDisplay={props.transformDisplaySubDistrict}
                  freeSolo
                  fullWidth
                  fuse
                  required
                  mt={6}
                />
                <Field
                  component={Select}
                  name="district"
                  type="text"
                  label="เขต/อำเภอ"
                  options={districtOptions}
                  required
                  mt={6}
                />
                <Field
                  component={Select}
                  name="province"
                  type="text"
                  label="จังหวัด"
                  options={provinceOptions}
                  freeSolo
                  fullWidth
                  required
                  mt={6}
                />
                <Field
                  component={Select}
                  name="zipcode"
                  type="text"
                  label="รหัสไปรษณีย์"
                  options={zipCodeOptions}
                  freeSolo
                  fullWidth
                  required
                  mt={6}
                />
              </Box>
            </Box>
            <Box mt={6}>
              <Typography variant="body" color="Text/Dark Grey">
                *ที่อยู่จะถูกแสดงให้ลูกค้าเห็นถ้าจำเป็นต้องมีการส่งวัสดุมาให้
              </Typography>
            </Box>

            <Box display="flex" mt={10} flex={1}>
              <Typography variant="h4">รายละเอียดงาน</Typography>
            </Box>
            <ErrorMessage with="100%" name="__error__" mt={6} />
            <Box display="flex" flexDirection="column" mt={3} flex={1}>
              <Box display="flex" flexDirection="column" flex={1} mt={6}>
                <Field
                  component={Select}
                  name="supplierType"
                  type=""
                  options={props.supplierTypeOptions}
                  label="ประเภทช่าง"
                  required
                  mt={6}
                />
                <Field
                  component={Select}
                  name="productAffordedGrades"
                  type=""
                  options={props.productAffordedGradeOptions}
                  label="เกรดงานที่ทำได้ (ใส่ได้หลายค่า)"
                  multiple
                  forceFix
                  mt={6}
                  requiredLabel
                />
                <Field
                  component={Select}
                  name="productTypes"
                  type=""
                  options={props.productTypeOptions}
                  label="ประเภทงานที่ถนัด (ใส่ได้หลายค่า)"
                  multiple
                  forceFix
                  mt={6}
                  requiredLabel
                />
              </Box>
            </Box>
            <Box display="flex" mt={10} flex={1}>
              <Typography variant="h4">ข้อความแนะนำตัว</Typography>
            </Box>
            <Box display="flex" flexDirection="column" flex={1} mt={6}>
              <Field component={Editor} name="greetingText" fullWidth />
            </Box>
            <Box mt={6}>
              <Typography variant="body" color="Text/Dark Grey">
                *ข้อความแนะนำตัวจะถูกแสดงให้ลูกค้าเห็นเมื่อลูกค้ากดดูข้อมูลของคุณ
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column">
              <FieldArray
                component={ExampleProductList}
                name="exampleProducts"
                addButtonLabel="เพิ่มรายการตัวอย่างงาน"
                onRemoveImage={props.deleteExampleProductImage}
              />
            </Box>
            <Divider mt={10} />

            <Box
              display="flex"
              flexDirection={props.isMobileSize ? "column" : "row"}
              justifyContent="space-between"
            >
              <Button
                type="submit"
                width={74}
                mt={10}
                className={props.classes.button}
              >
                บันทึก
              </Button>
              <Box mt={10}>
                <Link
                  style={{ cursor: "pointer", color: "#767676" }}
                  onClick={props.logout}
                >
                  ออกจากระบบ
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Form>
  </PageContent>
);

export const API = {
  FETCH_CONFIGS: gql`
    query FETCH_CONFIGS {
      config {
        detail
      }
    }
  `,
  FETCH_SUPPLIER: gql`
    query FETCH_SUPPLIER($id: String!, $exampleProductLimit: Float) {
      supplier(input: { id: $id, exampleProductLimit: $exampleProductLimit }) {
        id
        code
        firstName
        lastName
        nickName
        phoneNumber
        email
        line
        addressInfo
        district
        subDistrict
        province
        zipcode
        avatarUrl
        greetingText
        supplierType
        productAffordedGrades
        productTypes
        availableStatus
        exampleProducts {
          productImages {
            url
            filename
          }
          productTitle
          productDescription
        }
      }
    }
  `,
  UPDATE_SUPPLIER: gql`
    mutation UPDATE_SUPPLIER(
      $id: String!
      $firstName: String
      $lastName: String
      $nickName: String
      $phoneNumber: String
      $email: String
      $line: String
      $addressInfo: String
      $district: String
      $subDistrict: String
      $province: String
      $zipcode: String
      $greetingText: String
      $supplierType: String
      $productTypes: [String!]
      $productAffordedGrades: [String!]
      $exampleProducts: [ExampleProductInput!]
      $availableStatus: Boolean
    ) {
      editSupplierInfo(
        input: {
          id: $id
          firstName: $firstName
          lastName: $lastName
          nickName: $nickName
          phoneNumber: $phoneNumber
          email: $email
          line: $line
          addressInfo: $addressInfo
          district: $district
          subDistrict: $subDistrict
          province: $province
          zipcode: $zipcode
          greetingText: $greetingText
          supplierType: $supplierType
          productAffordedGrades: $productAffordedGrades
          productTypes: $productTypes
          exampleProducts: $exampleProducts
          availableStatus: $availableStatus
        }
      ) {
        id
        code
        firstName
        lastName
        nickName
        phoneNumber
        email
        line
        addressInfo
        district
        subDistrict
        province
        zipcode
        greetingText
        supplierType
        productAffordedGrades
        productTypes
        exampleProducts {
          productImages {
            url
            filename
          }
          productTitle
          productDescription
        }
        availableStatus
      }
    }
  `,
  RESET_PASSWORD: gql`
    mutation RESET_PASSWORD($email: String!) {
      resetPassword(input: { email: $email }) {
        currentUser {
          email
        }
      }
    }
  `,
};

// export const OPTIONS = {
//   supplierTypeOptions: [
//     { label: "ตัด", value: "ตัด" },
//     { label: "เย็บ", value: "เย็บ" },
//   ],
//   productAffordedGradeOptions: [
//     { label: "A", value: "A" },
//     { label: "A-", value: "A-" },
//     { label: "B+", value: "B+" },
//     { label: "B-", value: "B-" },
//   ],
//   productTypeOptions: [
//     { label: "เสื้อ", value: "เสื้อ" },
//     { label: "กางเกง", value: "กางเกง" },
//     { label: "หน้ากากผ้า", value: "หน้ากากผ้า" },
//   ],
// };

const enhancer = compose(
  // defaultProps({
  //   ...OPTIONS,
  // }),
  withFormik({
    mapPropsToValues: () => ({
      firstName: "",
      lastName: "",
      nickName: "",
      phoneNumber: "",
      email: "",
      line: "",
      supplierType: "",
      productAffordedGrades: [],
      productTypes: [],
      greetingText: "",
      exampleProducts: [{}],
    }),
    validationSchema: Yup.object().shape({
      email: Yup.string().nullable().email("รูปแบบอีเมลไม่ถูกต้อง"),
      firstName: Yup.string().nullable().required("ชื่อต้องไม่เว้นว่างเอาไว้"),
      lastName: Yup.string()
        .nullable()
        .required("นามสกุลต้องไม่เว้นว่างเอาไว้"),
      nickName: Yup.string()
        .nullable()
        .required("ชื่อเล่นต้องไม่เว้นว่างเอาไว้"),
    }),
  }),
  withHooks((props, hooks) => {
    const { setErrors, setValues, values, setFieldValue } = props;
    const {
      useQuery,
      useMutation,
      useMemo,
      useHandleSubmit,
      useEffect,
      useCallback,
    } = hooks;
    const { id } = props;

    const { loading, data, error, refetch } = useQuery(API.FETCH_SUPPLIER, {
      variables: { id },
    });

    const classes = useStyles();

    useEffect(() => {
      refetch();
    }, [refetch]);

    const {
      loading: loadingConfigs,
      data: configsData,
      error: configsError,
      refetch: refetchConfigs,
    } = useQuery(API.FETCH_CONFIGS);

    const [updateSupplier] = useMutation(API.UPDATE_SUPPLIER, {
      onCompleted: (data) => {
        appStore.setCurrentUser(data.editSupplierInfo);
      },
    });

    const [resetPasswordApi] = useMutation(API.RESET_PASSWORD);

    const supplier = useMemo(() => {
      if (loading || error) {
        return null;
      }
      return data?.supplier;
    }, [loading, data, error]);

    const configs = useMemo(() => {
      if (loadingConfigs || configsError) {
        return null;
      }
      return JSON.parse(configsData?.config?.detail);
    }, [loadingConfigs, configsData, configsError]);

    const productTypeOptions = useMemo(() => {
      if (!configs) return [];

      const customProductTypes = map(configs?.productTypes, (type) => {
        if (type.value === null || type.value === undefined) {
          return {
            ...type,
            value: type.label,
          };
        } else {
          return type;
        }
      });

      return customProductTypes || [];
    }, [configs]);

    const productAffordedGradeOptions = useMemo(() => {
      if (!configs) return [];

      const customGrades = map(configs?.grades, (grade) => {
        if (grade.value === null || grade.value === undefined) {
          return {
            ...grade,
            value: grade.label,
          };
        } else {
          return grade;
        }
      });

      return customGrades || [];
    }, [configs]);

    const supplierTypeOptions = useMemo(() => {
      if (!configs) return [];

      const customSupplierTypes = map(configs?.supplierTypes, (type) => {
        if (type.value === null || type.value === undefined) {
          return {
            ...type,
            value: type.label,
          };
        } else {
          return type;
        }
      });

      return customSupplierTypes || [];
    }, [configs]);
    useEffect(() => {
      if (supplier) setValues(supplier);
    }, [setValues, supplier]);

    useHandleSubmit(
      async (values) => {
        try {
          let errorMessageData = { __error__: null };
          if (values.productAffordedGrades.length === 0) {
            errorMessageData = {
              ...errorMessageData,
              productAffordedGrades: "เกรดงานที่ทำได้ต้องไม่เว้นว่างเอาไว้",
            };
          }
          if (values.productTypes.length === 0) {
            errorMessageData = {
              ...errorMessageData,
              productTypes: "ประเภทงานที่ไม่ถนัดต้องไม่เว้นว่างเอาไว้",
            };
          }
          setErrors(errorMessageData);
          if (
            values.productAffordedGrades.length > 0 &&
            values.productTypes.length > 0
          ) {
            await updateSupplier({ variables: values });
            Notification.success("แก้ไขข้อมูลสำเร็จ");
          }
        } catch (e) {
          const errorMessage = getErrorMessage(e);
          let errorMessageData = { __error__: errorMessage };

          if (errorMessage === "มีเบอร์โทรนี้ในระบบแล้ว") {
            errorMessageData = {
              ...errorMessageData,
              phoneNumber: errorMessage,
            };
          }
          if (errorMessage === "มีไลน์ไอดีในระบบแล้ว") {
            errorMessageData = {
              ...errorMessageData,
              line: errorMessage,
            };
          }
          setErrors(errorMessageData);
        }
      },
      [updateSupplier]
    );

    const email = supplier?.email;

    const resetPassword = useCallback(async () => {
      if (!email) {
        return;
      }
      Modal.open({
        title: "เปลี่ยนรหัสผ่าน",
        children: `ลิงก์สำหรับสร้างรหัสผ่านใหม่จะถูกส่งไปที่อีเมล ${email} กรุณาคลิกลิงก์จากอีเมลเพื่อสร้างรหัสผ่านในการเข้าสู่ระบบ`,
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ส่งเมล",
        onOk: async () => {
          try {
            await resetPasswordApi({ variables: { email } });
            removeToken();
            window.location.reload();
          } catch (error) {
            notifyError(error);
          }
        },
      });
    }, [email, resetPasswordApi]);

    const logout = useCallback(() => {
      Modal.open({
        title: "ออกจากระบบ",
        children: `ยืนยันการออกจากระบบ`,
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ออกจากระบบ",
        onOk: ({ close }) => {
          removeToken();
          close();
          window.location.reload();
        },
      });
    }, []);

    const disabledSubmitButton = useMemo(
      () => isEqual(values, data?.supplier),
      [values, data]
    );

    const currentSubDistrict = values.subDistrict;

    useEffect(() => {
      if (currentSubDistrict) {
        const [
          subDistrict,
          district,
          province,
          zipcode,
        ] = currentSubDistrict.split(" » ");

        const hasNestedField = !isNil(zipcode);

        if (hasNestedField) {
          setFieldValue("subDistrict", subDistrict);
          setFieldValue("district", district);
          setFieldValue("province", province);
          setFieldValue("zipcode", zipcode);
        }
      }
    }, [currentSubDistrict, setFieldValue]);

    const transformDisplaySubDistrict = useCallback((label) => {
      return "XXXXX-" + label;
    }, []);

    const isMobileSize = useMediaQuery("(max-width: 375px)");

    const editorRef = useRef(null);
    const editor = editorRef?.current?.getEditor();
    const unprivilegedEditor = editorRef?.current?.makeUnprivilegedEditor(
      editor
    );
    const resultText = unprivilegedEditor?.getText();

    const deleteExampleProductImage = useCallback(
      async (index, imageIndex, values) => {
        const customExampleProducts = map(
          values.exampleProducts,
          (exampleProduct, exampleProductIndex) => {
            if (index === exampleProductIndex) {
              const filteredProductImages = filter(
                exampleProduct.productImages,
                (_, productImageIndex) => imageIndex !== productImageIndex
              );
              return {
                ...exampleProduct,
                productImages: filteredProductImages,
              };
            } else {
              return exampleProduct;
            }
          }
        );

        try {
          await updateSupplier({
            variables: {
              ...values,
              greetingPlainText: resultText,
              exampleProducts: customExampleProducts,
            },
          });
          Notification.notify(
            <Box display="flex" flexDirection="row">
              <Box mr={1}>
                <CheckedIcon />
              </Box>
              {`ลบรูปสำเร็จ`}
            </Box>
          );
        } catch (e) {
          Notification.error("เกิดข้อผิดพลาด");
        }
      },
      [JSON.stringify(resultText)]
    );

    return {
      code: supplier?.code,
      resetPassword,
      disabledSubmitButton,
      supplierTypeOptions,
      productAffordedGradeOptions,
      productTypeOptions,
      transformDisplaySubDistrict,
      isMobileSize,
      logout,
      classes,
      deleteExampleProductImage,
    };
  })
);

export default enhancer(SupplierProfilePage);
