import { compose, withHooks, withFormik } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Typography,
  Form,
  Field,
  TextField,
  Button,
  Notification,
  Select,
  Grid,
  Editor,
  Divider,
  ImageUploadList,
  FieldArray,
  SwitchInput,
} from "components";
import { gql, paths } from "utils/helper";
import { map } from "lodash";

const MaterialNewPage = (props) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <Form>
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          flex={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4">ข้อมูลทั่วไป</Typography>
          <Field
            component={SwitchInput}
            name="availableStatus"
            label="แสดงในผลการค้นหา"
          />
        </Box>
        <FieldArray
          component={ImageUploadList}
          caption="รูปภาพวัสดุ"
          name="images"
          addButtonLabel="เพิ่มรูปวัสดุ"
          max={7}
          onRemoveImage={(removedIndex, props) => {
            const currentIndex = parseInt(
              props.form.values.highlightImagesIndex
            );
            if (!isNaN(currentIndex)) {
              if (removedIndex < currentIndex) {
                props.form.setFieldValue(
                  "highlightImagesIndex",
                  `${currentIndex - 1}`
                );
              } else if (removedIndex === currentIndex) {
                props.form.setFieldValue("highlightImagesIndex", null);
              }
            }
          }}
        />
        <Box display="flex" flex={1}>
          <Box display="flex" flexDirection="column" flex={1} mt={6} mr={6}>
            <Field
              component={TextField}
              name="name"
              type="text"
              label="ชื่อ"
              required
            />
          </Box>
          <Box display="flex" flexDirection="column" flex={1} mt={6} ml={6}>
            <Field
              component={Select}
              name="type"
              label="ประเภท"
              fullWidth
              options={props.fabricTypesOptions}
              required
              forceFix
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flex={1} mt={6}>
          <Field
            component={TextField}
            name="description"
            type="text"
            label="รายละเอียดวัสดุสำหรับแสดงให้ช่างและลูกค้า"
            required
            fullWidth
            multiline
            className="height-136"
          />
        </Box>
        <Box display="flex" flex={1}>
          <Box display="flex" flexDirection="column" flex={1} mt={6} mr={6}>
            <Field
              component={Select}
              name="unit"
              type="text"
              label="หน่วย"
              options={props.materialUnitsOptions}
              required
              forceFix
            />
          </Box>
          <Box display="flex" flexDirection="column" flex={1} mt={6} ml={6}>
            <Field
              component={TextField}
              name="pricePerUnit"
              type="number"
              label="ราคาต่อหน่วย"
              required
            />
          </Box>
        </Box>
        <Typography variant="h4" mt={8}>
          บันทึกเพิ่มเติม
        </Typography>
        <Grid container spacing={6} mt={2}>
          <Grid item xs={12}>
            <Field component={Editor} name="remark" label="แบรนด์" fullWidth />
          </Grid>
        </Grid>
        <Typography variant="body" mt={8} color="Text/Dark Grey">
          *บันทึกเพิ่มเติมสำหรับทีมงานจะไม่ถูกแสดงให้ช่างหรือลูกค้าเห็น
        </Typography>
        <Divider mt={10} />
        <Button type="submit" color="secondary" mt={6} width={74}>
          บันทึก
        </Button>
      </Box>
    </Form>
  </PageContent>
);

export const API = {
  FETCH_CONFIGS: gql`
    query FETCH_CONFIGS {
      config {
        detail
      }
    }
  `,
  CREATE_MATERIAL: gql`
    mutation CREATE_MATERIAL(
      $name: String
      $type: String
      $images: [Upload!]
      $availableStatus: Boolean
      $description: String
      $unit: String
      $pricePerUnit: Float
      $remark: String
    ) {
      createMaterial(
        input: {
          name: $name
          type: $type
          images: $images
          availableStatus: $availableStatus
          description: $description
          unit: $unit
          pricePerUnit: $pricePerUnit
          remark: $remark
        }
      ) {
        name
        type
        images {
          url
          filename
        }
        availableStatus
        description
        unit
        pricePerUnit
        remark
      }
    }
  `,
};

const enhancer = compose(
  withFormik({ mapPropsToValues: () => ({}) }),
  withHooks((props, hooks) => {
    const { setErrors } = props;
    const {
      useMutation,
      useMemo,
      useHandleSubmit,
      useQuery,
      useEffect,
    } = hooks;
    const [createMaterial] = useMutation(API.CREATE_MATERIAL);

    const { loading, data, error, refetch } = useQuery(API.FETCH_CONFIGS);

    const title = `เพิ่มวัสดุ`;

    useEffect(() => {
      refetch();
    }, [refetch]);

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.materialsPath(), label: "วัสดุ" },
        { path: null, label: title },
      ];
    }, [title]);

    const configs = useMemo(() => {
      if (loading || error) {
        return null;
      }
      return JSON.parse(data.config?.detail);
    }, [loading, data, error]);

    const fabricTypesOptions = useMemo(() => {
      if (!configs) return;

      const customMaterialTypes = map(configs?.materialTypes, (type) => {
        if (type.value === null || type.value === undefined) {
          return {
            ...type,
            value: type.label,
          };
        } else {
          return type;
        }
      });

      return customMaterialTypes || [];
    }, [configs]);

    const materialUnitsOptions = useMemo(() => {
      if (!configs) return;

      const customMaterialUnits = map(configs?.materialUnits, (unit) => {
        if (unit.value === null || unit.value === undefined) {
          return {
            ...unit,
            value: unit.label,
          };
        } else {
          return unit;
        }
      });

      return customMaterialUnits || [];
    }, [configs]);

    useHandleSubmit(
      async (values) => {
        try {
          await createMaterial({ variables: values });
          paths.materialsPath().push();
          Notification.success("เพิ่มวัสดุสำเร็จ");
        } catch (e) {
          setErrors({
            __error__: "มีอีเมลนี้ในระบบแล้ว",
            email: "มีอีเมลนี้ในระบบแล้ว",
          });
        }
      },
      [createMaterial]
    );

    return { title, breadcrumbs, fabricTypesOptions, materialUnitsOptions };
  })
);

export default enhancer(MaterialNewPage);
