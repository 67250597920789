import { findKey } from "lodash";
import { isClient } from "common/helper";

const config = {
  production: {
    HOST: "",
    API_ENDPOINT: "",
  },
  uat: {
    HOST: "https://admin.fabrix.legaldrive.moveplus.dynu.net",
    API_ENDPOINT: "https://admin.fabrix.legaldrive.moveplus.dynu.net/graphql",
  },
  staging: {
    HOST: "https://dev.admin.fabrix.legaldrive.moveplus.dynu.net",
    API_ENDPOINT: "https://dev.api.fabrix.legaldrive.moveplus.dynu.net/graphql",
  },
  local: {
    HOST: "http://localhost:20301",
    API_ENDPOINT: "http://localhost:20301/graphql",
  },
};

const getEnvByHost = () =>
  isClient ? findKey(config, { HOST: window.location.origin }) : null;

export const env = getEnvByHost() || process.env.APP_ENV || "local";

export default config[env];
