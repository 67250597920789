import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography, Modal, ExcelGenerator } from "components";
import {
  gql,
  notifyError,
  notifySuccess,
  paths,
  formatDate,
} from "utils/helper";
import { ReactComponent as AddIcon } from "assets/icon/add-icon.svg";
import { ReactComponent as RedTrashIcon } from "assets/icon/red_trash.svg";
import { ReactComponent as DownloadIcon } from "assets/icon/download-icon-white.svg";

const AdminIndexPage = (props) => (
  <PageContent
    title="ผู้ดูแลระบบ"
    pageActions={
      props.currentUserIsSuperAdmin
        ? [
            {
              children: "Export",
              startIcon: <DownloadIcon />,
              onClick: props.downloadProducts,
              color: "primary",
            },
            {
              children: "เพิ่มผู้ดูแลระบบ",
              startIcon: <AddIcon />,
              onClick: () => paths.adminNewPath().push(),
              color: "primary",
            },
          ]
        : [
            {
              children: "Export",
              startIcon: <DownloadIcon />,
              onClick: props.downloadProducts,
              color: "primary",
            },
          ]
    }
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายชื่อผู้ดูแลระบบ
      </Typography>
      <Table
        columns={[
          { width: 100, field: "code", headerName: "รหัส" },
          {
            width: 298,
            field: "fullName",
            headerName: "ชื่อ นามสกุล",
          },
          {
            width: 140,
            field: "phoneNumber",
            headerName: "เบอร์โทรศัพท์",
            type: "phoneNumber",
            // filterable: false,
          },
          { width: 340, field: "email", headerName: "อีเมล" },
          { width: 120, field: "line", headerName: "ไลน์" },
          {
            width: 160,
            field: "lastActiveAt",
            headerName: "ใช้งานล่าสุด",
            hide: true,
            type: "date",
          },
          {
            width: 160,
            field: "createdAt",
            headerName: "สร้างเมื่อ",
            hide: true,
            type: "date",
          },
          {
            width: 160,
            field: "updatedAt",
            headerName: "แก้ไขเมื่อ",
            hide: true,
            type: "date",
          },
          {
            width: 140,
            field: "role",
            headerName: "ตำแหน่ง",
            hide: true,
          },
          {
            width: 40,
            field: "actions",
            headerName: " ",
            filterable: false,
            sortable: false,
            type: "actions",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={
          props.currentUserIsSuperAdmin ? paths.adminEditPath : null
        }
        density="compact"
        autoHeight
        disableSelectionOnClick
        // includeToolbar
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_ADMINS: gql`
    query FETCH_ADMINS {
      admins {
        code
        createdAt
        email
        firstName
        lastName
        id
        isSuperAdmin
        line
        phoneNumber
        updatedAt
      }
      currentUser {
        admin {
          isSuperAdmin
        }
      }
    }
  `,
  DELETE_ADMINS: gql`
    mutation DELETE_ADMINS($ids: [String!]!) {
      deleteAdmins(input: { ids: $ids }) {
        admins {
          code
          createdAt
          email
          firstName
          lastName
          id
          isSuperAdmin
          line
          phoneNumber
          updatedAt
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useCallback, useQuery, useMutation, useEffect } = hooks;
    const { loading, data, error, refetch } = useQuery(API.FETCH_ADMINS);
    const [deleteAdmins] = useMutation(API.DELETE_ADMINS);

    useEffect(() => {
      refetch();
    }, [refetch]);

    const deleteAdmin = useCallback(
      async (params) => {
        const { id, email } = params.row;

        Modal.open({
          title: "ลบข้อผู้ดูแลระบบ",
          children: (
            <Box flex={1} mb={5} display="flex" flexDirection="row">
              <Typography variant="body1" color="#767676">
                ต้องการลบข้อมูลผู้ดูแลระบบ
              </Typography>
              <Box ml={1}>
                <Typography variant="body1" color="black" fontWeight={600}>
                  {email}
                </Typography>
              </Box>
            </Box>
          ),
          cancelButtonLabel: "ยกเลิก",
          okButtonLabel: "ลบข้อมูล",
          onOk: async ({ close }) => {
            try {
              await deleteAdmins({ variables: { ids: [id] } });
              await refetch();
              close();
              notifySuccess("ลบข้อมูลสำเร็จ");
            } catch (e) {
              notifyError(e);
            }
          },
        });
      },
      [deleteAdmins, refetch]
    );

    const currentUserIsSuperAdmin = data?.currentUser?.admin?.isSuperAdmin;

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }
      return data.admins.map((admin) => {
        const { firstName, lastName, isSuperAdmin, ...rest } = admin;

        return {
          ...rest,
          fullName: `${firstName || ""} ${lastName || ""}`,
          role: isSuperAdmin ? "Super Admin" : "Admin",
          actions: currentUserIsSuperAdmin
            ? [
                {
                  Icon: RedTrashIcon,
                  onClick: deleteAdmin,
                  can: !isSuperAdmin,
                },
              ]
            : [],
        };
      });
    }, [loading, data, error, deleteAdmin, currentUserIsSuperAdmin]);

    const downloadProducts = useCallback(async () => {
      ExcelGenerator.generate({
        fileName: `admins-${formatDate(new Date(), "yyyyMMddhhmmss")}`,
        columns: [
          {
            title: "รหัส",
            field: "code",
          },
          {
            title: "ชื่อ นามสกุล",
            field: "fullName",
          },
          {
            title: "เบอร์โทรศัพท์",
            field: "phoneNumber",
          },
          {
            title: "อีเมล",
            field: "email",
          },
          {
            title: "ไลน์",
            field: "line",
          },
        ],
        data: data.admins,
      });
    }, [data]);

    return {
      tableData,
      loading,
      currentUserIsSuperAdmin,
      downloadProducts,
    };
  })
);

export default enhancer(AdminIndexPage);
