import { compose, withFormik, withHooks } from "enhancers";
import { PageContent } from "layouts";

const DashboardPage = (props) => (
  <PageContent
    title="ภาพรวม"
    pageActions={props.pageActions}
    paper={false}
  ></PageContent>
);

const enhancer = compose(
  withFormik({}),
  withHooks(() => {
    return {};
  })
);

export default enhancer(DashboardPage);
