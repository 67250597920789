import React, { useRef } from "react";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";

import { compose, withHooks } from "enhancers";
import Box from "@material-ui/core/Box";

const JsonEditor = ({ layoutProps, editorProps }) => (
  <Box {...layoutProps}>
    <Editor {...editorProps} />
  </Box>
);

const enhancer = compose(
  withHooks((props, hooks) => {
    const { form, field, outerRef, schema, ...rest } = props;
    const { useMemo, useCallback, useEffect } = hooks;

    const { setFieldValue } = form;
    const { name, value } = field;

    const layoutProps = rest;

    const jsonEditorRef = useRef();

    const onChange = useCallback(
      (value) => {
        setFieldValue(name, value);
      },
      [setFieldValue, name]
    );

    const editorProps = useMemo(() => {
      return {
        onChange,
        value: value ?? "",
        ref: jsonEditorRef,
        outerRef: outerRef,
        schema: schema,
      };
    }, [onChange, value, outerRef, schema]);

    useEffect(() => {
      const editor =
        jsonEditorRef &&
        jsonEditorRef.current &&
        jsonEditorRef.current.jsonEditor;
      if (editor) {
        editor.expandAll();
      }
    }, [jsonEditorRef]);

    useEffect(() => {
      const editor =
        jsonEditorRef &&
        jsonEditorRef.current &&
        jsonEditorRef.current.jsonEditor;
      if (editor && value) {
        editor.update(value);
      }
    }, [jsonEditorRef, value]);

    return {
      layoutProps,
      editorProps,
    };
  })
);

export default enhancer(JsonEditor);
