import { compose, withHooks, withFormik } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Typography,
  Form,
  Field,
  TextField,
  Button,
  Divider,
  Notification,
} from "components";
import { gql, paths, Yup } from "utils/helper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#2296F3",
    borderColor: "#2296F3",
    color: "white",
    "&:hover": {
      backgroundColor: "#2296F3",
      borderColor: "#2296F3",
      color: "white",
    },
  },
}));

const SupplierNewPage = (props) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <Form>
      <Box display="flex" flexDirection="column">
        <Typography variant="h4">กรอกอีเมลของช่าง</Typography>
        <Typography variant="body1" color="textSecondary" mt={2}>
          ลิงก์เพื่อเข้าใช้งานระบบจะถูกส่งไปที่อีเมลนี้
        </Typography>
        <Field
          component={TextField.Email}
          name="email"
          label="อีเมล"
          // required
          width={512}
          mt={6}
        />
        <Divider mt={10} />
        <Button
          type="submit"
          // color="primary"
          width={74}
          p={0}
          mt={4}
          className={props.classes.button}
        >
          ส่งเมล
        </Button>
      </Box>
    </Form>
  </PageContent>
);

export const API = {
  CREATE_SUPPLIER: gql`
    mutation CREATE_SUPPLIER($email: String!) {
      createSupplier(input: { email: $email }) {
        supplier {
          code
          email
          firstName
          id
          line
          phoneNumber
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      email: "",
    }),
    validationSchema: Yup.object().shape({
      email: Yup.string().required("รูปแบบอีเมลไม่ถูกต้อง"),
    }),
  }),
  withHooks((props, hooks) => {
    const { setErrors } = props;
    const { useMutation, useMemo, useHandleSubmit } = hooks;
    const [createSupplier] = useMutation(API.CREATE_SUPPLIER);
    const classes = useStyles();

    const title = `เพิ่มช่าง`;

    const breadcrumbs = useMemo(() => {
      return [
        { path: paths.suppliersPath(), label: "ช่าง" },
        { path: null, label: title },
      ];
    }, [title]);

    useHandleSubmit(
      async (values) => {
        try {
          const { email } = values;
          await createSupplier({ variables: { email } });
          paths.suppliersPath().push();
          Notification.success("ส่งเมลสำเร็จ");
        } catch (e) {
          setErrors({
            email: "มีอีเมลนี้ในระบบแล้ว",
          });
        }
      },
      [createSupplier]
    );

    return { title, breadcrumbs, classes };
  })
);

export default enhancer(SupplierNewPage);
