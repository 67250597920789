import React from "react";
import { compose, withHooks, withFormik } from "enhancers";
import styled from "styled-components/macro";
import {
  Form,
  Field,
  TextField,
  Button,
  Alert,
  Avatar,
  Paper,
  Typography,
  Helmet,
  Modal,
  Box,
} from "components";
import { gql, Yup, paths, notifyError } from "utils/helper";
import { ReactComponent as Logo } from "assets/image/logo.svg";
import { useMediaQuery } from "@material-ui/core";

const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

type ResetPasswordPageProps = {
  errorMessage?: string;
  isMobileSize: boolean;
};

const ResetPasswordPage = (props: ResetPasswordPageProps) => (
  <Paper
    width="100%"
    py={props.isMobileSize ? 6 : 10}
    px={props.isMobileSize ? 4 : 10}
    style={{ alignSelf: props.isMobileSize ? "flex-start" : "center" }}
  >
    <Helmet title="Setup Password" />
    {/* <Avatar
      alt="Fabrix"
      src="/static/img/fabrix_logo.jpg"
      variant="square"
      mx="auto"
    /> */}
    <LogoContainer>
      <Logo></Logo>
    </LogoContainer>
    <Typography component="h1" variant="h4" align="center" mt={10}>
      ลืมรหัสผ่าน
    </Typography>
    <Typography component="h2" variant="caption" align="center" mt={2}>
      กรอกอีเมลเพื่อสร้างรหัสผ่านในการเข้าสู่ระบบใหม่
    </Typography>
    <Form>
      {props.errorMessage && (
        <Alert severity="error" mt={6}>
          {props.errorMessage}
        </Alert>
      )}
      <Field
        component={TextField.Email}
        name="email"
        label="อีเมล"
        fullWidth
        mt={6}
      />

      <Button type="submit" color="secondary" fullWidth mt={10}>
        ส่ง
      </Button>
    </Form>
  </Paper>
);

const API = {
  RESET_PASSWORD: gql`
    mutation RESET_PASSWORD($email: String!) {
      resetPassword(input: { email: $email }) {
        currentUser {
          email
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      email: "",
    }),
    validationSchema: Yup.object().shape({
      email: Yup.string().email("รูปแบบอีเมลไม่ถูกต้อง"),
    }),
  }),
  withHooks((props: any, hooks: any) => {
    const { useHandleSubmit, useMutation, useMemo } = hooks;

    const [resetPassword, { loading, error }] = useMutation(API.RESET_PASSWORD);

    useHandleSubmit(
      async (values: any) => {
        try {
          const { email } = values;
          await resetPassword({ variables: { email } });

          const closeModalAndGotoSignin = ({ close }: any) => {
            close();
            paths.signInPath().push();
          };

          Modal.alert({
            className: "ConfirmResetPasswordModal",
            title: "ส่งเมลสำเร็จ",
            children: (
              <Box display="flex" flexWrap="wrap">
                <Typography variant="body1">
                  ลิงก์สำหรับสร้างรหัสผ่านใหม่ได้ถูกส่งไปที่อีเมล
                  <b>{` ${email} `}</b>
                  เรียบร้อยแล้ว
                  กรุณาคลิกลิงก์จากอีเมลเพื่อสร้างรหัสผ่านในการเข้าสู่ระบบ
                </Typography>
              </Box>
            ),
            okButtonLabel: "ปิด",
            okButtonVariant: "text",
            onOk: closeModalAndGotoSignin,
            onClose: closeModalAndGotoSignin,
          });
        } catch (e: any) {
          console.error(e);
        }
      },
      [resetPassword]
    );

    const isMobileSize = useMediaQuery("(max-width: 375px)");

    const errorMessage = useMemo(() => {
      if (!loading && error) {
        return "ไม่พบอีเมลนี้ กรุณาติดต่อผู้ดูแลระบบ";
      }
    }, [loading, error]);

    return {
      errorMessage,
      isMobileSize,
    };
  })
);

export default enhancer(ResetPasswordPage);
