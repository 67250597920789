import { compose, withHooks } from "enhancers";
import { PageContent } from "layouts";
import { Box, Table, Typography, ExcelGenerator } from "components";
import { gql, paths, formatDate } from "utils/helper";
import { useMediaQuery } from "@material-ui/core";
import { BOOKING_STATUS_TEXT } from "../../../../constants/index";

import { ReactComponent as DownloadIcon } from "assets/icon/download-icon-white.svg";

const SupplierBookingIndexPage = (props) => (
  <PageContent
    title="การจองคิว"
    noPadding={props.shouldRemovePadding}
    pageActions={[
      {
        children: "Export",
        startIcon: <DownloadIcon />,
        onClick: props.downloadProducts,
        color: "primary",
      },
    ]}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        รายการจองคิว
      </Typography>
      <Table
        columns={[
          {
            width: 100,
            field: "bookingStatus",
            headerName: "สถานะ",
            type: "status",
          },
          {
            width: 100,
            field: "productType",
            headerName: "ประเภท",
          },
          {
            width: 55,
            field: "productGrade",
            headerName: "เกรด",
          },
          {
            width: 70,
            field: "productAmout",
            headerName: "จำนวน",

            align: "right",
          },

          {
            width: 230,
            field: "productReceivedDueDate",
            headerName: "วันที่ต้องการรับของ",
            type: "dateOnly",
          },
          {
            width: 230,
            field: "customerName",
            headerName: "ชื่อลูกค้า",
          },
        ]}
        rows={props.tableData}
        loading={props.loading}
        onRowClickTo={paths.supplierBookingDetailPath}
        density="compact"
        autoHeight
        disableSelectionOnClick
      />
    </Box>
  </PageContent>
);

export const API = {
  FETCH_BOOKINGS: gql`
    query FETCH_BOOKINGS {
      supplierBookings {
        id
        code
        supplierName
        customerName
        productType
        productGrade
        productAmout
        productReceivedDueDate
        bookingStatus
      }
    }
  `,
  CREATE_BOOKING: gql`
    mutation CREATE_BOOKING($id: String) {
      createBooking(input: { id: $id }) {
        booking {
          productType
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const { useMemo, useCallback, useQuery, useMutation, useEffect } = hooks;

    const { loading, data, error, refetch } = useQuery(API.FETCH_BOOKINGS);
    const [createBooking] = useMutation(API.CREATE_BOOKING);

    const isMobileSize = useMediaQuery("(max-width: 375px)");

    useEffect(() => {
      refetch();
    }, [refetch]);

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data.supplierBookings.map((booking, index) => {
        return {
          ...booking,
          actions: [],
        };
      });
    }, [loading, data, error]);

    const addMockupBooking = useCallback(async () => {
      await createBooking();
    }, [createBooking]);

    const shouldRemovePadding = useMemo(() => {
      return isMobileSize;
    }, [isMobileSize]);

    const excelData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      const bookings = data.supplierBookings.map((booking) => {
        const status = BOOKING_STATUS_TEXT[booking.bookingStatus];

        return {
          ...booking,
          bookingStatus: status,
        };
      });

      return bookings;
    }, [loading, data, error]);

    const downloadProducts = useCallback(async () => {
      ExcelGenerator.generate({
        fileName: `supplier-bookings-${formatDate(
          new Date(),
          "yyyyMMddhhmmss"
        )}`,
        columns: [
          {
            title: "สถานะ",
            field: "bookingStatus",
          },
          {
            title: "ประเภท",
            field: "productType",
          },
          {
            title: "เกรด",
            field: "productGrade",
          },
          {
            title: "จำนวน",
            field: "productAmout",
          },
          {
            title: "วันที่ต้องการรับของ",
            field: "productReceivedDueDate",
          },
          {
            title: "ชื่อลูกค้า",
            field: "customerName",
          },
        ],
        data: excelData,
      });
    }, [excelData]);

    return {
      downloadProducts,
      addMockupBooking,
      tableData,
      loading,
      shouldRemovePadding,
    };
  })
);

export default enhancer(SupplierBookingIndexPage);
