import { compose, withHooks } from "enhancers";
import { makeStyles } from "@material-ui/core/styles";
import { PageContent } from "layouts";
import {
  Box,
  Typography,
  Divider,
  ImageDownloader,
  Button,
  Modal,
  Notification,
} from "components";
import {
  gql,
  notifyError,
  paths,
  getErrorMessage,
  formatDate,
  toCurrency,
  formatPhoneNumber,
} from "utils/helper";
import { join, groupBy, reduce, isEmpty } from "lodash";
import { differenceInDays } from "date-fns";
import { ReactComponent as Warning } from "assets/icon/warning.svg";
import { ReactComponent as ConfirmIcon } from "assets/icon/checked.svg";
import { ReactComponent as ExclamationIcon } from "assets/icon/exclamation.svg";
import ConfirmModalDetail from "./confirm_modal_detail";
import CancelModalDetail from "./cancel_modal_detail";
import styled from "styled-components/macro";

import { ReactComponent as File } from "assets/icon/file_blue.svg";

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    backgroundColor: "white",
    borderColor: "#F34336",
    color: "#F34336",
    "&:hover": {
      backgroundColor: "white",
      color: "#F34336",
    },
  },
  confirmButton: {
    backgroundColor: "#2296F3",
    borderColor: "#2296F3",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#2296F3",
      color: "#fff",
    },
  },
}));

const Touchable = styled.div`
  display: flex;
  align-items: flex-start;

  :hover {
    cursor: pointer;
  }
`;

const BookingDetailPage = (props) => (
  <PageContent
    title={props.booking?.code}
    breadcrumbs={[
      { path: paths.bookingsPath(), label: "การจอง" },
      { path: null, label: props.booking?.code },
    ]}
    banner={props.statusBanner}
  >
    <Box width="100%" display="flex" flexDirection="column">
      <Box width="100%" display="flex" flexDirection="row" flexWrap="wrap">
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          mb={6}
        >
          <Typography variant="h4">ข้อมูลลูกค้า</Typography>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            mt={6}
            bgcolor="#E7F4FD"
            paddingTop={4.5}
            paddingBottom={4.5}
            paddingLeft={4}
            paddingRight={4}
            borderRadius={8}
            mr={6}
          >
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography variant="body1" mb={4} width={130}>
                รหัส
              </Typography>
              <Typography variant="body1" mb={4} width={24}>
                :
              </Typography>
              <Typography variant="body1" mb={4} flex={1}>
                {props.customer?.code ?? ""}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography variant="body1" mb={4} width={130}>
                ชื่อ-นามสกุล
              </Typography>
              <Typography variant="body1" mb={4} width={24}>
                :
              </Typography>
              <Typography variant="body1" mb={4} flex={1}>
                {`${props.customer?.firstName ?? ""} ${
                  props.customer?.lastName ?? ""
                } ${
                  props.customer?.nickName
                    ? `(${props.customer?.nickName})`
                    : ""
                }`}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography variant="body1" mb={4} width={130}>
                ที่อยู่
              </Typography>
              <Typography variant="body1" mb={4} width={24}>
                :
              </Typography>
              <Typography variant="body1" mb={4} flex={1}>
                {props.customerAddress ?? ""}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography variant="body1" mb={4} width={130}>
                อีเมล
              </Typography>
              <Typography variant="body1" mb={4} width={24}>
                :
              </Typography>
              <Typography variant="body1" mb={4} flex={1}>
                {props.customer?.email ?? ""}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography variant="body1" mb={4} width={130}>
                เบอร์โทรศัพท์
              </Typography>
              <Typography variant="body1" mb={4} width={24}>
                :
              </Typography>
              <Typography variant="body1" mb={4} flex={1}>
                {formatPhoneNumber(props.customer?.phoneNumber) ?? ""}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          mb={6}
        >
          <Typography variant="h4" ml={0}>
            ข้อมูลช่าง
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            mt={6}
            bgcolor="#E7F4FD"
            paddingTop={4.5}
            paddingBottom={4.5}
            paddingLeft={4}
            paddingRight={4}
            borderRadius={8}
            ml={0}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
              >
                <Typography variant="body1" mb={4} width={130}>
                  รหัส
                </Typography>
                <Typography variant="body1" mb={4} width={24}>
                  :
                </Typography>
                <Typography variant="body1" mb={4} flex={1}>
                  {props.supplier?.code}
                </Typography>
              </Box>
              <Touchable
                onClick={() =>
                  window.open(
                    `/suppliers/${props.supplier?.id}/edit`,
                    "__blank"
                  )
                }
              >
                <File />
              </Touchable>
            </Box>

            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography variant="body1" mb={4} width={130}>
                ชื่อ-นามสกุล
              </Typography>
              <Typography variant="body1" mb={4} width={24}>
                :
              </Typography>
              <Typography variant="body1" mb={4} flex={1}>
                {`${props.supplier?.firstName ?? ""} ${
                  props.supplier?.lastName ?? ""
                } ${
                  props.supplier?.nickName
                    ? `(${props.supplier?.nickName})`
                    : ""
                }`}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography variant="body1" mb={4} width={130}>
                ที่อยู่
              </Typography>
              <Typography variant="body1" mb={4} width={24}>
                :
              </Typography>
              <Typography variant="body1" mb={4} flex={1}>
                {props.supplierAddress ?? ""}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography variant="body1" mb={4} width={130}>
                อีเมล
              </Typography>
              <Typography variant="body1" mb={4} width={24}>
                :
              </Typography>
              <Typography variant="body1" mb={4} flex={1}>
                {props.supplier?.email}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography variant="body1" mb={4} width={130}>
                เบอร์โทรศัพท์
              </Typography>
              <Typography variant="body1" mb={4} width={24}>
                :
              </Typography>
              <Typography variant="body1" mb={4} flex={1}>
                {formatPhoneNumber(props.supplier?.phoneNumber)}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography variant="body1" width={130}>
                Line ID
              </Typography>
              <Typography variant="body1" width={24}>
                :
              </Typography>
              <Typography variant="body1" flex={1}>
                {props.supplier?.line}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        width="100%"
        mt={4}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
      >
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          mb={6}
        >
          <Typography variant="h4">ข้อมูลผลิตภัณฑ์</Typography>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            mt={6}
            bgcolor="#E7F4FD"
            paddingTop={4.5}
            paddingBottom={4.5}
            paddingLeft={4}
            paddingRight={4}
            borderRadius={8}
            mr={6}
          >
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography variant="body1" mb={4} width={130}>
                ประเภทการตัดเย็บ
              </Typography>
              <Typography variant="body1" mb={4} width={24}>
                :
              </Typography>
              <Typography variant="body1" mb={4} flex={1}>
                {props.booking?.productType ?? ""}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography variant="body1" mb={4} width={130}>
                เกรดงาน
              </Typography>
              <Typography variant="body1" mb={4} width={24}>
                :
              </Typography>
              <Typography variant="body1" mb={4} flex={1}>
                {props.booking?.productGrade ?? ""}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography variant="body1" mb={4} width={130}>
                จำนวนรวม
              </Typography>
              <Typography variant="body1" mb={4} width={24}>
                :
              </Typography>
              <Typography variant="body1" mb={4} flex={1}>
                {props.booking?.productAmout ?? ""}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography variant="body1" mb={4} width={130}>
                วันที่ต้องการรับของ
              </Typography>
              <Typography variant="body1" mb={4} width={24}>
                :
              </Typography>
              <Typography variant="body1" mb={4} flex={1}>
                {props.booking?.productReceivedDueDate
                  ? `${formatDate(
                      props.booking?.productReceivedDueDate,
                      "dd/MM/yyyy"
                    )} (${
                      differenceInDays(
                        new Date(props.booking?.productReceivedDueDate),
                        new Date()
                      ) < 0
                        ? 0
                        : differenceInDays(
                            new Date(props.booking?.productReceivedDueDate),
                            new Date()
                          )
                    } วัน)`
                  : ""}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography variant="body1" mb={4} width={130}>
                การตกแต่ง
              </Typography>
              <Typography variant="body1" mb={4} width={24}>
                :
              </Typography>
              <Typography variant="body1" mb={4} flex={1}>
                {props.productDescription ?? ""}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-start">
              <Typography variant="body1" mb={4} width={130}>
                ราคาประเมิน
              </Typography>
              <Typography variant="body1" mb={4} width={24}>
                :
              </Typography>
              <Typography variant="body1" mb={4} flex={1}>
                {`${
                  props.booking?.minEstimatedCost
                    ? toCurrency(props.booking?.minEstimatedCost)
                    : 0
                } ถึง ${
                  props.booking?.maxEstimatedCost
                    ? toCurrency(props.booking?.maxEstimatedCost)
                    : 0
                } บาท`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          mb={6}
        >
          <Typography variant="h4" ml={0}>
            สี/ไซส์
          </Typography>
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            mt={6}
            bgcolor="#E7F4FD"
            paddingTop={4.5}
            paddingBottom={4.5}
            paddingLeft={4}
            paddingRight={4}
            borderRadius={8}
            ml={0}
          >
            {props.productDetailValue?.map((productDetail) => (
              <Box mt={2}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  mb={2}
                >
                  <Typography variant="body1" width={130}>
                    {`${productDetail.key} (${productDetail.amount})`}
                  </Typography>
                  <Typography variant="body1" width={24}>
                    :
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                  >
                    {productDetail.values?.map((value) => (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                      >
                        <Typography
                          variant="body1"
                          flex={1}
                        >{`${value.key} X ${value.amount}`}</Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
      >
        <Typography variant="h4" mt={10}>
          ข้อมูลผลิตภัณฑ์เพิ่มเติม
        </Typography>
        {props.booking?.remark && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            bgcolor="#FFF4E5"
            padding={2.5}
            mt={6}
          >
            <Warning />
            <Typography variant="body1" color="#FF9800" ml={2}>
              {props.booking?.remark}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        mt={4.5}
      >
        {props.booking?.images?.map((img) => (
          <ImageDownloader value={img} />
        ))}
      </Box>
      <Divider />
      {props.booking?.bookingStatus === "waiting" ? (
        <Box display="flex" flexDirection="row" justifyContent="flex-start">
          <Button
            // color="#F34336"
            // bgcolor="white"
            // variant="outlined"
            mt={6}
            width={146}
            onClick={props.openCancelModal}
            className={props.classes.cancelButton}
          >
            ปฏิเสธการจองคิว
          </Button>
          <Button
            // color="white"
            // bgcolor="#2296F3"
            mt={6}
            ml={6}
            width={146}
            onClick={props.openConfirmModal}
            className={props.classes.confirmButton}
          >
            ยืนยันการจองคิว
          </Button>
        </Box>
      ) : props.booking?.bookingStatus === "confirmed" ? (
        <Box display="flex" flexDirection="row" justifyContent="flex-start">
          <Button
            // color="white"
            // bgcolor="#2296F3"
            mt={6}
            width={146}
            onClick={props.openConfirmPaymentModal}
            className={props.classes.confirmButton}
          >
            ชำระเงินแล้ว
          </Button>
        </Box>
      ) : props.booking?.bookingStatus === "paid" ? (
        <Box display="flex" flexDirection="row" justifyContent="flex-start">
          <Button
            // color="white"
            // bgcolor="#2296F3"
            mt={6}
            width={171}
            onClick={props.openConfirmOrderModal}
            className={props.classes.confirmButton}
          >
            คำสั่งซื้อเสร็จสมบูรณ์
          </Button>
        </Box>
      ) : null}
    </Box>
  </PageContent>
);

export const API = {
  FETCH_ฺBOOKING: gql`
    query FETCH_BOOKING($id: String!) {
      booking(id: $id) {
        code
        productType
        productGrade
        productAmout
        productReceivedDueDate
        productDescription
        minEstimatedCost
        maxEstimatedCost
        productSizeAndColor
        bookingStatus
        confirmedBy
        updatedAt
        rejectedRemark
        supplier {
          id
          code
          firstName
          lastName
          nickName
          address {
            addressInfo
            subDistrict
            district
            province
            zipcode
          }
          email
          phoneNumber
          line
        }
        customer {
          code
          firstName
          lastName
          nickName
          email
          phoneNumber
        }
        remark
        images {
          url
          filename
        }
      }
    }
  `,
  CONFIRM_BOOKING: gql`
    mutation CONFIRM_BOOKING($id: String) {
      confirmBooking(input: { id: $id }) {
        status
      }
    }
  `,
  CANCEL_BOOKING: gql`
    mutation CANCEL_BOOKING($id: String, $rejectedRemark: String!) {
      cancelBooking(input: { id: $id, rejectedRemark: $rejectedRemark }) {
        status
      }
    }
  `,
  CONFIRM_PAYMENT: gql`
    mutation CONFIRM_PAYMENT($id: String) {
      confirmPayment(input: { id: $id }) {
        code
        productType
        productGrade
        productAmout
        productReceivedDueDate
        productDescription
        minEstimatedCost
        maxEstimatedCost
        productSizeAndColor
        bookingStatus
        confirmedBy
        updatedAt
        supplier {
          code
          firstName
          lastName
          nickName
          address {
            addressInfo
            subDistrict
            district
            province
            zipcode
          }
          email
          phoneNumber
          line
        }
        customer {
          code
          firstName
          lastName
          nickName
          email
          phoneNumber
        }
        remark
        images {
          url
          filename
        }
      }
    }
  `,
  CONFIRM_ORDER: gql`
    mutation CONFIRM_ORDER($id: String) {
      confirmOrder(input: { id: $id }) {
        code
        productType
        productGrade
        productAmout
        productReceivedDueDate
        productDescription
        minEstimatedCost
        maxEstimatedCost
        productSizeAndColor
        bookingStatus
        confirmedBy
        updatedAt
        supplier {
          code
          firstName
          lastName
          nickName
          address {
            addressInfo
            subDistrict
            district
            province
            zipcode
          }
          email
          phoneNumber
          line
        }
        customer {
          code
          firstName
          lastName
          nickName
          email
          phoneNumber
        }
        remark
        images {
          url
          filename
        }
      }
    }
  `,
};

const enhancer = compose(
  withHooks((props, hooks) => {
    const {
      useQuery,
      useMutation,
      useMemo,
      useHandleSubmit,
      useEffect,
      useCallback,
      useParams,
      useState,
    } = hooks;
    const { id } = useParams();
    const { loading, data, error, refetch } = useQuery(API.FETCH_ฺBOOKING, {
      variables: { id },
    });

    const [confirmBookingApi] = useMutation(API.CONFIRM_BOOKING);
    const [cancelBookingApi] = useMutation(API.CANCEL_BOOKING);
    const [confirmPaymentApi] = useMutation(API.CONFIRM_PAYMENT);
    const [confirmOrderApi] = useMutation(API.CONFIRM_ORDER);

    const [bookingData, setBookingData] = useState(data?.booking);
    const classes = useStyles();

    useEffect(() => {
      refetch();
    }, [refetch]);

    useEffect(() => {
      setBookingData(data?.booking);
    }, [JSON.stringify(data)]);

    const booking = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.bookingsPath().push();
        return null;
      }
      return bookingData;
    }, [loading, bookingData, error]);

    const supplier = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.bookingsPath().push();
        return null;
      }
      return bookingData?.supplier;
    }, [loading, bookingData, error]);

    const customer = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.bookingsPath().push();
        return null;
      }
      return bookingData?.customer;
    }, [loading, bookingData, error]);

    const productDescription = useMemo(() => {
      const productString = join(booking?.productDescription, "/");
      return productString;
    }, [booking]);

    const productSizeAndColor = useMemo(() => {
      if (!booking) return;

      const productSizeAndColor = JSON.parse(booking?.productSizeAndColor);
      if (productSizeAndColor && !isEmpty(productSizeAndColor[0])) {
        return productSizeAndColor;
      } else {
        return null;
      }
    }, [booking]);

    const productDetailValue = useMemo(() => {
      if (!productSizeAndColor) return;

      const groupedColor = groupBy(productSizeAndColor, (item) => item.color);

      const keys = Object.keys(groupedColor);
      const values = Object.values(groupedColor);
      const entries = Object.entries(groupedColor);

      const groupedProductByColor = entries.map((item) => {
        const key = item[0];
        const value = item[1];

        const amount = reduce(value, (sum, item) => sum + item.amount, 0);
        const groupSize = groupBy(value, (item) => item.size);

        const groupSizeEntries = Object.entries(groupSize);
        const groupSizeValue = groupSizeEntries.map((item) => {
          const groupKey = item[0];
          const groupValue = item[1];

          const groupamount = reduce(
            groupValue,
            (sum, item) => sum + item.amount,
            0
          );

          const result = {
            key: groupKey,
            amount: groupamount,
          };

          return result;
        });

        const result = {
          key: key,
          amount: amount,
          values: groupSizeValue,
        };

        return result;
      });

      return groupedProductByColor;
    }, [productSizeAndColor]);

    const supplierAddress = useMemo(() => {
      if (!supplier?.address) return;

      const address = supplier?.address;
      const info = address.addressInfo;
      const subDistrict = address.subDistrict;
      const district = address.district;
      const province = address.province;
      const zipcode = address.zipcode;

      return `${info} ${subDistrict} ${district} ${province} ${zipcode}`;
    }, [supplier?.address]);

    const customerAddress = useMemo(() => {
      if (!customer?.address) return;

      const address = customer?.address;
      const info = address.addressInfo;
      const subDistrict = address.subDistrict;
      const district = address.district;
      const province = address.province;
      const zipcode = address.zipcode;

      return `${info} ${subDistrict} ${district} ${province} ${zipcode}`;
    }, [customer?.address]);

    const BG_COLOR = {
      waiting: "#FF9800",
      confirmed: "#376FD0",
      paid: "#376FD0",
      complete: "#4CAF4F",
      canceled: "#F34336",
    };

    const BANNER_MESSAGE = {
      confirmed: "ยืนยันการจองโดย",
      paid: "ยืนยันการชำระเงินโดย",
      complete: "ยืนยันคำสั่งซื้อเสร็จสมบูรณ์โดย",
      canceled: "ปฏิเสธการจองคิว โดย",
    };

    const ICON = {
      confirmed: <ConfirmIcon />,
      paid: <ExclamationIcon />,
      complete: <ExclamationIcon />,
      canceled: <ExclamationIcon />,
    };

    const statusBanner = useMemo(() => {
      const { bookingStatus, confirmedBy, updatedAt, rejectedRemark } =
        booking || {};

      const confirmedDate = `${formatDate(updatedAt, "dd/MM/yyyy")}`;

      const shouldDisplayBanner =
        bookingStatus === "confirmed" ||
        bookingStatus === "paid" ||
        bookingStatus === "complete" ||
        bookingStatus === "canceled";

      const bannerMessage =
        bookingStatus === "canceled"
          ? `${BANNER_MESSAGE[bookingStatus]} ${confirmedBy} เมื่อ ${confirmedDate} เหตุผล: ${rejectedRemark}`
          : `${BANNER_MESSAGE[bookingStatus]} ${confirmedBy} เมื่อ ${confirmedDate}`;
      return shouldDisplayBanner ? (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          bgcolor={BG_COLOR[bookingStatus]}
          padding={2.5}
          mt={6}
          mb={6}
          borderRadius={4}
        >
          {ICON[bookingStatus]}
          <Typography variant="body1" color="white" ml={2}>
            {bannerMessage}
          </Typography>
        </Box>
      ) : null;
    }, [booking, BG_COLOR]);

    const confirmBooking = useCallback(async () => {
      try {
        await confirmBookingApi({
          variables: { id },
        });
        await Modal.close();
        paths.bookingsPath().push();
      } catch (error) {
        await Modal.close();
        Notification.error(error.toString());
      }
    }, [confirmBookingApi]);

    const cancelBooking = useCallback(
      async (remark) => {
        if (!remark) return;
        const params = {
          id: id,
          rejectedRemark: remark,
        };
        await cancelBookingApi({
          variables: params,
        });
        await Modal.close();
        paths.bookingsPath().push();
      },
      [cancelBookingApi]
    );

    const confirmPayment = useCallback(async () => {
      await confirmPaymentApi({
        variables: { id },
      });
      await Modal.close();
      await refetch();
    }, [confirmPaymentApi, refetch]);

    const confirmOrder = useCallback(async () => {
      await confirmOrderApi({
        variables: { id },
      });
      await Modal.close();
      await refetch();
    }, [confirmOrderApi, refetch]);

    const openCancelModal = useCallback(() => {
      Modal.open({
        children: (rest) => (
          <CancelModalDetail
            {...rest}
            onSubmit={(values) => cancelBooking(values.description)}
          />
        ),
        onOk: ({ handleSubmit }) => {
          handleSubmit();
        },
        okButtonLabel: "ปฏิเสธและแจ้งเหตุผล",
      });
    }, [cancelBooking]);

    const openConfirmModal = useCallback(() => {
      Modal.open({
        children: <ConfirmModalDetail booking={booking} />,
        onOk: confirmBooking,
        okButtonLabel: "ยืนยันการจองคิว",
      });
    }, [booking, confirmBooking]);

    const openConfirmPaymentModal = useCallback(() => {
      Modal.open({
        children: (
          <Box flex={1} mb={5} display="flex" flexDirection="column">
            <Typography variant="body1" color="black" fontWeight={600}>
              ชำระเงินแล้ว
            </Typography>
            <Box flex={1} display="flex" flexDirection="column" mt={2}>
              <Typography variant="body1" color="#767676">
                บันทึกในระบบว่าได้รับเงินจากลูกค้าเรียบร้อยแล้ว
              </Typography>
            </Box>
          </Box>
        ),
        onOk: confirmPayment,
        okButtonLabel: "ยืนยัน",
      });
    }, [confirmPayment]);

    const openConfirmOrderModal = useCallback(() => {
      Modal.open({
        children: (
          <Box flex={1} mb={5} display="flex" flexDirection="column">
            <Typography variant="body1" color="black" fontWeight={600}>
              คำสั่งซื้อเสร็จสมบูรณ์
            </Typography>
            <Box flex={1} display="flex" flexDirection="column" mt={2}>
              <Typography variant="body1" color="#767676">
                บันทึกในระบบว่าลูกค้าได้รับของเรียบร้อยแล้ว
              </Typography>
            </Box>
          </Box>
        ),
        onOk: confirmOrder,
        okButtonLabel: "ยืนยัน",
      });
    }, [confirmOrder]);

    return {
      classes,
      booking,
      supplier,
      customer,
      productDescription,
      productDetailValue,
      supplierAddress,
      customerAddress,
      openCancelModal,
      openConfirmModal,
      statusBanner,
      confirmPayment,
      confirmOrder,
      openConfirmPaymentModal,
      openConfirmOrderModal,
    };
  })
);

export default enhancer(BookingDetailPage);
