import paths from "routes/paths";

import { RouteType } from "types/routes";

// Menu Icon
import { ReactComponent as AdminIcon } from "vendor/menu_icon/admin.svg";

export const ADMIN_MAIN_MENU: RouteType[] = [
  {
    id: "ผู้ดูแลระบบ",
    path: paths.adminsPath().toString(),
    header: "เมนู",
    icon: <AdminIcon />,
  },
  // {
  //   id: "ช่าง",
  //   path: paths.suppliersPath().toString(),
  //   icon: <SupplierIcon />,
  // },
  // {
  //   id: "การจองคิว",
  //   path: paths.bookingsPath().toString(),
  //   icon: <BookingIcon />,
  // },
  // {
  //   id: "วัสดุ",
  //   path: paths.materialsPath().toString(),
  //   icon: <MaterailIcon />,
  // },
];

export const SUPER_ADMIN_MAIN_MENU: RouteType[] = [
  {
    id: "ผู้ดูแลระบบ",
    path: paths.adminsPath().toString(),
    header: "เมนู",
    icon: <AdminIcon />,
  },
  // {
  //   id: "ช่าง",
  //   path: paths.suppliersPath().toString(),
  //   icon: <SupplierIcon />,
  // },
  // {
  //   id: "การจองคิว",
  //   path: paths.bookingsPath().toString(),
  //   icon: <BookingIcon />,
  // },
  // {
  //   id: "วัสดุ",
  //   path: paths.materialsPath().toString(),
  //   icon: <MaterailIcon />,
  // },
  // {
  //   id: "ตั้งค่า",
  //   path: paths.configsPath().toString(),
  //   icon: <ConfigIcon />,
  // },
];

export const SUPPLIER_MAIN_MENU: RouteType[] = [
  {
    id: "การจองคิว",
    path: paths.supplierBookingsPath().toString(),
    header: "เมนู",
    icon: <AdminIcon />,
  },
  // {
  //   id: "คำสั่งซื้อ",
  //   path: paths.suppliersPath().toString(),
  //   icon: <SupplierIcon />,
  // },
];

// export default MAIN_MENU;
