import { compose, withHooks, withFormik } from "enhancers";
import { PageContent } from "layouts";
import styled from "styled-components/macro";
import {
  Box,
  Typography,
  Form,
  Field,
  TextField,
  Button,
  ErrorMessage,
  Notification,
  Divider,
  Modal,
} from "components";
import { gql, paths, Yup, notifyError, getErrorMessage } from "utils/helper";
import { removeToken } from "api";
import appStore from "stores/appStore";
import { isEqual } from "lodash";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#2296F3",
    borderColor: "#2296F3",
    color: "white",
    "&:hover": {
      backgroundColor: "#2296F3",
      borderColor: "#2296F3",
      color: "white",
    },
  },
}));

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;

  :hover {
    cursor: pointer;
  }
`;

const AdminProfilePage = (props) => (
  <PageContent
    title={`โปรไฟล์ - ${props.code}`}
    // breadcrumbs={[
    //   { path: paths.homePath(), label: "หน้าแรก" },
    //   { path: null, label: `โปรไฟล์ - ${props.code}` },
    // ]}
  >
    <Form>
      <Box display="flex" flexDirection="column">
        <Typography variant="h4">ข้อมูลทั่วไป</Typography>
        <ErrorMessage with="100%" name="__error__" mt={6} />
        <Box mt={6}>
          <Box display="flex">
            <Box display="flex" flex={1}>
              <Box display="flex" flexDirection="column" flex={1} mr={6}>
                <Field
                  component={TextField}
                  name="firstName"
                  type="text"
                  label="ชื่อ"
                  required
                />
                <Field
                  component={TextField.Email}
                  name="email"
                  label="อีเมล"
                  disabled
                  mt={6}
                />
                <Field
                  component={TextField}
                  name="line"
                  type="text"
                  label="LINE ID"
                  mt={6}
                />
              </Box>
              <Box display="flex" flexDirection="column" flex={1} mr={6}>
                <Field
                  component={TextField}
                  name="lastName"
                  type="text"
                  label="นามสกุล"
                  required
                />
                <Field
                  component={TextField.PhoneNumber}
                  name="phoneNumber"
                  label="เบอร์โทรศัพท์"
                  mt={6}
                />
              </Box>
            </Box>
            {/*<Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width={327}
            >
              <Field component={UploadAvatar} name="avatarFile" />
            </Box>*/}
          </Box>
          <Divider mt={10} />
          {/*<Box mt={6}>
            <Link
              underline="always"
              style={{ cursor: "pointer" }}
              onClick={props.resetPassword}
            >
              เปลี่ยนรหัสผ่าน
            </Link>
          </Box>*/}
          <Box display="flex" justifyContent="space-between">
            <Button
              type="submit"
              // color="primary"
              width={74}
              mt={10}
              className={props.classes.button}
            >
              บันทึก
            </Button>
            <MenuItem onClick={props.logout}>
              <Typography variant="body1" color="#767676">
                ออกจากระบบ
              </Typography>
            </MenuItem>
          </Box>
        </Box>
      </Box>
    </Form>
  </PageContent>
);

export const API = {
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      currentUser {
        admin {
          id
          code
          firstName
          lastName
          phoneNumber
          email
          line
          avatarUrl
        }
      }
    }
  `,
  UPDATE_ADMIN: gql`
    mutation UPDATE_ADMIN(
      $id: String!
      $firstName: String
      $lastName: String
      $phoneNumber: String
      $email: String
      $line: String
      $avatarFile: Upload
    ) {
      editAdminInfo(
        input: {
          id: $id
          firstName: $firstName
          lastName: $lastName
          phoneNumber: $phoneNumber
          email: $email
          line: $line
          avatarFile: $avatarFile
        }
      ) {
        admin {
          id
          code
          firstName
          lastName
          phoneNumber
          email
          line
          avatarUrl
        }
      }
    }
  `,
  RESET_PASSWORD: gql`
    mutation RESET_PASSWORD($email: String!) {
      resetPassword(input: { email: $email }) {
        currentUser {
          email
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      line: "",
      avatarFile: null,
    }),
    validationSchema: Yup.object().shape({
      email: Yup.string().nullable().email("รูปแบบอีเมลไม่ถูกต้อง"),
      firstName: Yup.string().nullable().required("ชื่อต้องไม่เว้นว่างเอาไว้"),
      lastName: Yup.string()
        .nullable()
        .required("นามสกุลต้องไม่เว้นว่างเอาไว้"),
    }),
  }),
  withHooks((props, hooks) => {
    const { setErrors, setValues, values } = props;
    const {
      useQuery,
      useMutation,
      useMemo,
      useHandleSubmit,
      useEffect,
      useCallback,
    } = hooks;
    const { loading, data, error } = useQuery(API.GET_CURRENT_USER);
    const classes = useStyles();

    const [updateAdmin] = useMutation(API.UPDATE_ADMIN, {
      onCompleted: (data) => {
        appStore.setCurrentUser(data.editAdminInfo.admin);
      },
    });

    const [resetPasswordApi] = useMutation(API.RESET_PASSWORD);

    const admin = useMemo(() => {
      if (loading || error) {
        return null;
      }
      return data.currentUser.admin;
    }, [loading, data, error]);

    useEffect(() => {
      setValues(admin);
    }, [setValues, admin]);

    useHandleSubmit(
      async (values) => {
        try {
          await updateAdmin({ variables: values });
          Notification.success("แก้ไขข้อมูลสำเร็จ");
        } catch (e) {
          const errorMessage = getErrorMessage(e);
          let errorMessageData = { __error__: errorMessage };

          if (errorMessage === "มีเบอร์โทรนี้ในระบบแล้ว") {
            errorMessageData = {
              ...errorMessageData,
              phoneNumber: errorMessage,
            };
          }

          if (errorMessage === "มีไลน์ไอดีในระบบแล้ว") {
            errorMessageData = {
              ...errorMessageData,
              line: errorMessage,
            };
          }
          setErrors(errorMessageData);
        }
      },
      [updateAdmin]
    );

    const email = admin?.email;

    const resetPassword = useCallback(async () => {
      if (!email) {
        return;
      }
      Modal.open({
        title: "เปลี่ยนรหัสผ่าน",
        children: `ลิงก์สำหรับสร้างรหัสผ่านใหม่จะถูกส่งไปที่อีเมล ${email} กรุณาคลิกลิงก์จากอีเมลเพื่อสร้างรหัสผ่านในการเข้าสู่ระบบ`,
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ส่งเมล",
        onOk: async () => {
          try {
            await resetPasswordApi({ variables: { email } });
            removeToken();
            window.location.reload();
          } catch (error) {
            notifyError(error);
          }
        },
      });
    }, [email, resetPasswordApi]);

    const disabledSubmitButton = useMemo(
      () => isEqual(values, data?.currentUser.admin),
      [values, data]
    );

    const logout = useCallback(() => {
      Modal.open({
        title: "ออกจากระบบ",
        children: `ยืนยันการออกจากระบบ`,
        cancelButtonLabel: "ยกเลิก",
        okButtonLabel: "ออกจากระบบ",
        onOk: ({ close }) => {
          removeToken();
          close();
          window.location.reload();
        },
      });
    }, []);

    return {
      code: admin?.code,
      resetPassword,
      disabledSubmitButton,
      logout,
      classes,
    };
  })
);

export default enhancer(AdminProfilePage);
